import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import { func } from "@/assets/js";
import money from "v-money";
import Print from "vue-print-nb";

import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

// Global registration
Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);

import "./assets/css/main2.css";
import "./assets/css/fontawesome-free-6.5.2/css/all.min.css";
import "./assets/css/bootstrap-icons-1.11.3/font/bootstrap-icons.min.css";

import "bootstrap-vue/dist/bootstrap-vue.css";

import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);

// ----- font-awesome

// end of font-awesome

Vue.config.productionTip = false;
Vue.prototype.$func = func;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueToast);
Vue.use(money, { precision: 0 });
Vue.use(Print);

if (process.env.NODE_ENV === "development") {
  // Vue.prototype.$appUrl = "http://localhost:8800/sisbeer/";
  Vue.prototype.$appUrl = "http://192.168.100.29:8800/sisbeer/";
} else {
  Vue.prototype.$appUrl = "php.script/";
}

Vue.prototype.$globalData = Vue.observable({
  UserId: "",
  LoginOK: "",
  TokenOK: 0,
  CurrentYear: "22",
  // Token: localStorage.getItem("mytokenBonding"),
  LevelLogin: "",
  ShowChangePassword: false,
  islogin: true,
  pasfoto: "",
  hakuser: [],
  datauser: {},
  isactive: "dashboard",
  Token: "",
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
