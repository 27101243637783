var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mb-2"},[_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"mb-0"},[_vm._v("List Konseling "+_vm._s(_vm.parameter))])]},proxy:true}])},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-input-group',{staticClass:"mb-2",attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"placeholder":"pencarian topik"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getdata()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-input-group-append',{attrs:{"is-text":"","variant":"primary"}},[_c('b-icon-search',{staticStyle:{"cursor":"pointer"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.getdata()}}})],1)],1)],1),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table","size":"sm"},on:{"input":function($event){return _vm.getdata()}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('span',{staticClass:"mt-2 ml-2",staticStyle:{"font-size":"12px"}},[_vm._v("#"+_vm._s(_vm.totalRows))])],1)])]),_c('b-table',{staticStyle:{"font-size":"14px"},attrs:{"hover":"","fields":[
          'no',
          'nokonseling',
          'tanggal',
          'nama',
          'jenis',
          'status',
        ],"items":_vm.datadisiplin,"responsive":"","tbody-tr-class":_vm.rowClass},on:{"row-clicked":_vm.dataselected},scopedSlots:_vm._u([{key:"cell(no)",fn:function(row){return [_vm._v(" "+_vm._s(row.index + (_vm.currentPage - 1) * _vm.perPage + 1)+" ")]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Data Konseling "+_vm._s(_vm.parameter)+" ("+_vm._s(_vm.form.nokonseling)+") ")])])]},proxy:true}])},[_c('b-form',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('div',{staticClass:"row mb-0",attrs:{"size":"sm"}},[_c('div',{staticClass:"col-md-8"},[_vm._v(" Nama Siswa: "),_c('vue-bootstrap-typeahead',{ref:"typeahead",staticClass:"mb-4",attrs:{"size":"sm","data":_vm.users,"serializer":(item) => item.nama,"placeholder":"pencarian nama"},on:{"hit":function($event){_vm.selectedUser = $event;
                _vm.form.nis = _vm.selectedUser.nis;
                _vm.form.noasrama = _vm.selectedUser.noasrama;
                _vm.form.kelas = _vm.selectedUser.nokelas;}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('div',{staticClass:"col-md mt-1"},[_c('br'),_vm._v("NIS:"+_vm._s(_vm.selectedUser.nis))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-auto"},[_c('b-img',{staticClass:"lonjong-image",attrs:{"src":_vm.$appUrl + _vm.selectedUser.pasfoto,"rounded":""}})],1),_c('div',{staticClass:"col-md text-left"},[_c('div',[_vm._v(" Nama Wali "),_c('b',[_vm._v(_vm._s(_vm.selectedUser.namawali))]),_vm._v(" "),_c('br'),_vm._v(" Asrama "),_c('b',[_vm._v(_vm._s(_vm.selectedUser.namaasrama)+" "+_vm._s(_vm.selectedUser.noasrama))]),_c('br'),_vm._v(" NoHP Ortu "),_c('b',[_vm._v(_vm._s(_vm.selectedUser.nohp))]),_vm._v(" "),_c('br'),_vm._v(" Kelas "),_c('b',[_vm._v(_vm._s(_vm.selectedUser.nokelas)+", "+_vm._s(_vm.selectedUser.namakelas))])])])]),_c('div',{staticClass:"row mt-2 mb-2"},[_c('div',{staticClass:"col-md"},[_vm._v(" Tanggal: "),_c('b-form-input',{attrs:{"type":"date","required":"","size":"sm"},model:{value:(_vm.form.tanggal),callback:function ($$v) {_vm.$set(_vm.form, "tanggal", $$v)},expression:"form.tanggal"}})],1),_c('div',{staticClass:"col-md"},[_vm._v(" Jam: "),_c('b-form-input',{attrs:{"type":"time","required":"","size":"sm"},model:{value:(_vm.form.waktu),callback:function ($$v) {_vm.$set(_vm.form, "waktu", $$v)},expression:"form.waktu"}})],1),_c('div',{staticClass:"col-md"},[_vm._v(" Kategori: "),_c('b-form-select',{staticClass:"col",staticStyle:{"background-color":"white","font-weight":"bold"},attrs:{"size":"sm","options":[
                  { value: 'ringan', text: 'ringan' },
                  { value: 'sedang', text: 'Sedang' },
                  { value: 'berat', text: 'Berat' },
                ]},model:{value:(_vm.form.kategori),callback:function ($$v) {_vm.$set(_vm.form, "kategori", $$v)},expression:"form.kategori"}})],1)]),_vm._v(" Jenis Koseling: "),_c('b-form-select',{staticClass:"mb-0 mb-2",attrs:{"options":_vm.listdisiplin,"size":"sm","required":""},model:{value:(_vm.form.jeniskonseling),callback:function ($$v) {_vm.$set(_vm.form, "jeniskonseling", $$v)},expression:"form.jeniskonseling"}}),_vm._v(" Topik: "),_c('b-form-input',{staticClass:"mb-2",attrs:{"required":"","size":"sm"},model:{value:(_vm.form.topik),callback:function ($$v) {_vm.$set(_vm.form, "topik", $$v)},expression:"form.topik"}}),_vm._v(" Deskripsi: "),_c('b-form-textarea',{staticClass:"mb-2",attrs:{"required":"","size":"sm"},model:{value:(_vm.form.deskripsi),callback:function ($$v) {_vm.$set(_vm.form, "deskripsi", $$v)},expression:"form.deskripsi"}}),_c('div',{staticClass:"row justify-content-between ml-2"},[_c('div',[_c('b-button',{staticClass:"mr-2 mb-2",attrs:{"type":"submit","variant":"primary","size":"sm"}},[_vm._v(_vm._s(_vm.isnew ? "Submit" : "Update"))]),_c('b-button',{staticClass:"mr-2 mb-2",attrs:{"type":"reset","variant":"danger","size":"sm"}},[_vm._v("Reset")]),_vm._v(" Mode: "+_vm._s(_vm.isnew ? "New" : "Edit")+" ")],1),_c('b-input-group',{staticClass:"mb-2 col-md-4 float-right",attrs:{"prepend":"Status","size":"sm"}},[_c('b-form-select',{staticStyle:{"background-color":"white","font-weight":"bold"},attrs:{"size":"sm","options":[
                  { value: '0', text: 'Open' },
                  { value: '1', text: 'Closed' },
                ]},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)],1),_c('hr'),_c('Upload',{attrs:{"nomorid":_vm.form.nokonseling}})],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }