<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 mb-2">
        <b-card>
          <template #header>
            <h4 class="mb-0">List Kesehatan</h4>
          </template>
          <div class="row">
            <div class="col-md-6">
              <b-input-group size="sm" class="mb-2">
                <b-form-input v-model="search" placeholder="cari Nama/penyakit" @keyup.enter="getdata()" />
                <b-input-group-append is-text variant="primary">
                  <b-icon-search size="sm" style="cursor: pointer" @click="getdata()" />
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-md-6">
              <div class="row">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"
                  @input="getdata()" size="sm"></b-pagination>
                <span style="font-size: 12px" class="mt-2 ml-2">#{{ totalRows }}</span>
              </div>
            </div>
          </div>
          <!-- total {{ totalRows }} -->
          <!-- {{ datadisiplin }} -->
          <b-table hover :fields="['no', 'nokesehatan', { key: 'tanggal', sortable: true }, { key: 'nama', sortable: true }
            , { key: 'jenis', sortable: true, label: 'Diagnosa' }]" :items="datadisiplin" @row-clicked="dataselected"
            responsive style="font-size: 14px" :tbody-tr-class="rowClass" :sort-by.sync="sortby"
            :sort-desc.sync="sortdesc" sort-icon-left @sort-changed="sortingChanged" small>
            <template #cell(no)="row">
              {{ row.index + (currentPage - 1) * perPage + 1 }}
            </template>
          </b-table>

        </b-card>
        <b-card class="mt-2">
          <template #header>
            <h4 class="mb-0">Summary Kesehatan</h4>
          </template>
          <div class="row">
            <div class="col-md-4 col-8">
              Dari Tanggal:
              <b-form-input type="date" v-model="tg1" size="sm" />
            </div>
            <div class="col-md-4 col-8">
              Sd. Tanggal:
              <b-form-input type="date" v-model="tg2" size="sm" />
            </div>
            <div class="col-md-1 col-3">
              <br>
              <b-button size="sm" @click="getsummary">OK</b-button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <b-table small :items="summary1" style="font-size: 12px" :fields="['namaasrama', 'L', 'P', 'jml']"
                :tbody-tr-class="rowClass1" />
            </div>
            <div class="col">
              <b-table small :items="summary2" style="font-size: 12px" :fields="['namakelas', 'L', 'P', 'jml']"
                :tbody-tr-class="rowClass1" />
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-md-6">
        <b-card>
          <template #header>
            <div>
              <h4 class="mb-0">
                Data Kesehatan (
                {{ form.nokesehatan }})
              </h4>
            </div>
          </template>
          <b-form @submit="onSubmit" @reset="onReset">
            <div class="row mb-0" size="sm">
              <div class="col-md-8">
                Nama Siswa:
                <vue-bootstrap-typeahead class="mb-4" size="sm" ref="typeahead" v-model="query" :data="users"
                  :serializer="(item) => item.nama" @hit="
                    selectedUser = $event;
                  form.nis = selectedUser.nis;
                  form.noasrama = selectedUser.noasrama;
                  form.kelas = selectedUser.nokelas;
                  " placeholder="pencarian nama" />
              </div>

              <div class="col-md mt-1"><br />NIS:{{ selectedUser.nis }}</div>
              <!-- {{ form.nis }},{{ selectedUser }} -->
            </div>
            <div class="row">
              <div class="col-md-3">
                <b-img :src="$appUrl + selectedUser.pasfoto" class="lonjong-image" rounded />
              </div>
              <div class="col-md-auto">
                Nama Wali <b>{{ selectedUser.namawali }}</b> <br />
                Asrama
                <b>{{ selectedUser.namaasrama }} {{ selectedUser.noasrama }}</b><br />
                NoHP Ortu <b>{{ selectedUser.nohp }}</b> <br />
                Kelas
                <b>{{ selectedUser.nokelas }}, {{ selectedUser.namakelas }} </b>
              </div>
            </div>
            <div class="row mt-2 mb-2">
              <div class="col-md">
                Tanggal Pengobatan:
                <b-form-input type="date" v-model="form.tanggal" required size="sm" />
              </div>
              <div class="col-md">
                <!-- Kategori: -->
                <b-form-select v-show="false" v-model="form.kategori" class="col" size="sm"
                  style="background-color: white; font-weight: bold" :options="[
                    { value: 'ringan', text: 'ringan' },
                    { value: 'sedang', text: 'Sedang' },
                    { value: 'berat', text: 'Berat' },
                  ]" />
                <!-- {{ form.waktu }} -->
              </div>
            </div>
            Diagnosa:
            <b-form-select class="mb-0 mb-2" v-model="form.jenispenyakit" :options="listdisiplin" size="sm" required />
            <div v-show="form.jenispenyakit == '99'" class="mb-2">
              Penyakit Lainnya:
              <b-form-input size="sm" v-model="form.lainpenyakit" />
            </div>
            Keluhan:
            <b-form-textarea class="mb-2" v-model="form.kronologis" required size="sm" />
            Tindakan:
            <b-form-textarea class="mb-2" v-model="form.catatanpengobatan" required size="sm" />
            <b-card header="Pemberian Obat:">
              <!-- {{ obat }} -->
              <!-- <b-button size="sm" @click="saveobat"><b-icon-save /></b-button> -->
              <table class="col-md mt-0">
                <tr>
                  <th class="text-center">
                    <!-- <b-button variant="outline-primary" style="font-size: 12px"> -->
                    <b-icon-plus-square style="cursor: pointer !important"
                      @click="obat.push({ kode: '', jumlah: 1 })" />
                    <!-- </b-button> -->
                  </th>
                  <th>Nama Obat</th>
                  <!-- <th>Nama Obat</th> -->
                  <th class="text-right">Jumlah</th>
                </tr>
                <tbody v-for="(det, k) in obat" :key="k">
                  <tr>
                    <td>
                      <!-- <b-button-group size="sm"> -->

                      <b-button variant="outline-primary" style="font-size: 12px"><b-icon-trash
                          @click="obat.splice(k, 1)" /></b-button>
                      <!-- </b-button-group> -->
                    </td>
                    <td>
                      <b-form-select v-model="det.kode" :options="listobat" size="sm" />
                    </td>
                    <!-- <td>{{ det.namaobat }}</td> -->
                    <td>
                      <b-form-input class="text-right" v-model="det.jumlah" size="sm" type="number" />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colspan="2">
                      <b-form-input class="text-left" v-model="det.lainobat" size="sm" v-show="det.kode == 'ZZ99'" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card>
            <b-card header="Perizinan Kepulangan" class="mt-2 p-0">
              <div class="row">
                <div class="col-md-6">
                  <b-input-group prepend="Diizinkan Pulang" append="Hari" size="sm">
                    <b-form-input size="sm" type="number" value="1" v-model="form.jmlhari" />
                  </b-input-group>
                </div>
                <div class="col-md-6">
                  <b-input-group prepend="Tanggal" size="sm">
                    <b-form-input size="sm" type="date" v-model="form.tanggalpulang" />
                  </b-input-group>
                </div>
              </div>
              <b-button size="sm" class="float-right mt-2" :disabled="form.nokesehatan == ''">
                <b-icon-arrow-right-square /> Perizinan</b-button>
            </b-card>
            <div class="row justify-content-between ml-2 mt-2">
              <div>
                <b-button @click="deleterec" variant="primary" size="sm" class="mr-2 mb-2"><b-icon-trash />
                  Delete</b-button>
                <b-button type="submit" variant="primary" size="sm" class="mr-2 mb-2">{{ isnew ? "Submit" : "Update"
                  }}</b-button>
                <b-button type="reset" variant="danger" size="sm" class="mr-2 mb-2">Reset</b-button>
                Mode: {{ isnew ? "New" : "Edit" }}
              </div>
            </div>
            <!-- {{ form }} -->
            <hr />
            <Upload :nomorid="form.nokesehatan" />
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Upload from "@/components/upload.vue";
import _ from "lodash";

export default {
  name: "Pengobatan",
  components: { Upload },
  data() {
    return {
      options: {},
      selectedCountry: null,
      datadisiplin: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 15,
      search: "",
      isnew: true,
      deskripsi: "",
      dok: null,
      listdisiplin: [],
      form: {
        identify: "",
        status: "0",
        nis: "",
        unik: "",
        tanggal: "",
        kronologis: "",
        tahun: "",
        kategori: "ringan",
        userid: "",
        noasrama: "",
        nokesehatan: "",
        kelas: "",
        jenispenyakit: "01",
        catatanpengobatan: "",
        jmlhari: 0,
        tanggalpulang: "",
        lainpenyakit: "",
        lainobat: "",
      },
      obat: [{ kode: "", jumlah: 1, lainobat: "" }],
      selectedItem: "",
      suggestionsList: [],
      query: "",
      users: [],
      selectedUser: {},
      listobat: [],
      selectedrow: "",
      sortby: '',
      sortdesc: '',
      tg1: '',
      tg2: '',
      summary1: [],
      summary2: [],
    };
  },
  mounted() {
    this.form.tanggal = this.$func.tglhariini();
    this.tg1 = this.$func.tglhariini();
    this.tg2 = this.$func.tglhariini();

    this.form.waktu = "10:00";
    this.getdata();
    axios
      .get(
        this.$appUrl + `listpenyakit.php` + "?token=" + this.$globalData.Token
      )
      .then((res) => {
        this.listdisiplin = res.data;
      });
    axios
      .get(this.$appUrl + `listobat.php` + "?token=" + this.$globalData.Token)
      .then((res) => {
        this.listobat = res.data;
      });
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    querys(newQuery) {
      axios
        .get(
          this.$appUrl +
          `searchdisiplinuks.php?q=${newQuery}` +
          "&token=" +
          this.$globalData.Token + "&user=" +
          this.$globalData.UserId +
          "&level=" +
          this.$globalData.LevelLogin
        )
        .then((res) => {
          this.users = res.data;
        });
    },
    query: _.debounce(function (newQuery) {
      this.searchUsers(newQuery);
    }, 500),
  },

  methods: {
    getsummary() {
      axios
        .get(
          this.$appUrl +
          `getsummarykes.php?tg1=` +
          this.tg1 + "&tg2=" + this.tg2 +
          "&token=" +
          this.$globalData.Token
        )
        .then((res) => {
          this.summary1 = res.data.summary1;
          this.summary2 = res.data.summary2;
          let totalL = 0;
          let totalP = 0;
          this.summary1.forEach((item) => {
            totalL += parseFloat(item.L);
            totalP += parseFloat(item.P);
          });

          // Menambahkan baris total ke summary1
          this.summary1.push({
            namaasrama: 'Total', // Nama asrama khusus untuk baris total
            L: totalL,
            P: totalP,
            jml: totalL + totalP
          });
          totalL = 0;
          totalP = 0;
          this.summary2.forEach((item) => {
            totalL += parseFloat(item.L);
            totalP += parseFloat(item.P);
          });

          // Menambahkan baris total ke summary1
          this.summary2.push({
            namakelas: 'Total', // Nama asrama khusus untuk baris total
            L: totalL,
            P: totalP,
            jml: totalL + totalP
          });

        });

    },
    sortingChanged(ctx) {
      console.log("sorting ", ctx);
      this.sortby = ctx.sortBy;
      this.sortdesc = ctx.sortDesc;
      this.currentPage = 1;
      this.getdata();
    },
    deleterec() { },
    getobat(nokes) {
      axios
        .get(
          this.$appUrl +
          `getobat.php?nokesehatan=` +
          nokes +
          "&token=" +
          this.$globalData.Token
        )
        .then((res) => {
          this.obat = res.data;
        });
    },
    saveobat() {
      console.log("data obat", this.obat);
      if (this.form.nokesehatan == "") {
        return;
      }
      axios.post(
        this.$appUrl +
        "saveobat.php?token=" +
        this.$globalData.Token +
        "&isnew=" +
        this.isnew +
        "&username=" +
        this.$globalData.UserId +
        "&nokesehatan=" +
        this.form.nokesehatan,
        { data: this.obat },
        {
          crossDomain: true,
        }
      );
    },
    searchUsers(newQuery) {
      axios
        .get(
          this.$appUrl +
          `searchdisiplinuks.php?q=${newQuery}` +
          "&token=" +
          this.$globalData.Token + "&user=" +
          this.$globalData.UserId +
          "&level=" +
          this.$globalData.LevelLogin
        )
        .then((res) => {
          this.users = res.data;
        });
    },
    onReset(event) {
      event.preventDefault();
      // this.form = {};
      // this.form.nodisiplin = "";
      this.isnew = true;
      this.form = {
        identify: 0,
        nis: "",
        unik: "",
        kronologis: "",
        kategori: "0",
        tahun: "",
        userid: "",
        evaluasi: "",
        noasrama: "",
        nodisiplin: "",
        catatanpengobatan: "",
        status: "0",
        jmlhari: 0,
        tanggalpulang: "",
        nokesehatan: "",
        lainpenyakit: "",
      };
      this.$refs.typeahead.inputValue = "";
      this.selectedUser = {};
      this.obat = [];
    },
    rowClass(item, type) {
      // console.log("item", item.nodisiplin, this.selectedrow);
      if (!item || type !== "row") return;
      if (item.nokesehatan === this.selectedrow) return "table-success";
    },
    rowClass1(item) {
      console.log("item", item.namakelas);
      if (item.namakelas === 'Total' || item.namaasrama === 'Total') return ['table-success', 'font-weight-bold']
    },
    dataselected(item) {
      console.log("item selected ", item);
      this.selectedrow = item.nokesehatan;
      this.isnew = 0;
      this.form = {
        identify: item.identify,
        nis: item.nis,
        unik: item.unik,
        tanggal: item.tanggal,
        jenispenyakit: item.jenispenyakit,
        kronologis: item.kronologis,
        tahun: item.tahun,
        kategori: item.kategori,
        userid: item.userid,
        kelas: item.kelas,
        noasrama: item.noasrama,
        nokesehatan: item.nokesehatan,
        catatanpengobatan: item.catatanpengobatan,
        nama: item.nama,
        status: item.status,
        jmlhari: item.jmlhari,
        tanggalpulang: item.tanggalpulang,
        lainpenyakit: item.lainpenyakit,
      };
      this.$refs.typeahead.inputValue = item.nama;
      this.selectedUser.nis = item.nis;
      this.selectedUser.namaasrama = item.namaasrama;
      this.selectedUser.noasrama = item.noasrama;
      this.selectedUser.nohp = item.nohp;
      this.selectedUser.nokelas = item.kelas;
      this.selectedUser.namawali = item.namawali;
      this.selectedUser.pasfoto = item.pasfoto;
      this.getobat(this.form.nokesehatan);
      // alert("masuk doubleklik");
    },

    getdata() {
      axios
        .get(
          this.$appUrl +
          "getkesehatan.php?token=" +
          this.$globalData.Token +
          "&page=" +
          this.currentPage +
          "&perpage=" +
          this.perPage +
          "&jmlrow=" +
          this.totalRows +
          "&q=" +
          this.search + "&sort=" + this.sortby + "&sortdesc=" + this.sortdesc
        )
        .then((res) => {
          // if (res.data.length != 0) {
          //   this.details = res.data;
          // }
          console.log("Data ", res.data);
          this.datadisiplin = res.data.records;
          if (this.currentPage == 1) {
            this.totalRows = res.data.jumlahrecord;
          }
          console.log(
            "rows",
            this.totalRows,
            this.datadisiplin,
            res.data.records
          );
        });
    },
    onSubmit(event) {
      if (this.form.jenispenyakit == '99') {
        if (this.form.lainpenyakit == '') {
          this.$bvModal.msgBoxOk('Jenis Penyakit harus diisi')
          return 0
        }
      }
      event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Yakin untuk di Simpan ?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post(
                this.$appUrl +
                "savekesehatan.php?token=" +
                this.$globalData.Token +
                "&isnew=" +
                this.isnew +
                "&username=" +
                this.$globalData.UserId,
                { data: this.form },
                {
                  crossDomain: true,
                }
              )
              .then((res) => {
                console.log("data", res.data);
                if (this.isnew == 1) {
                  this.form.nokesehatan = res.data[0].nokesehatan;
                }
                this.saveobat();
                this.$toast.success(
                  this.isnew == 1 ? "Sukses Save" : "Sukses Update",
                  {
                    type: "success",
                    position: "top-right",
                    duration: 3000,
                    dismissible: true,
                  }
                ); //   output.className = 'container';
                this.isnew = 0;
                // this.refresh("2");
              });
          }
        });
    },
    searchItems() {
      console.log("search item");
    },
  },
};
</script>

<style>
.gradient {
  background: linear-gradient(to bottom, #134a6e 33%, #66ff99 100%);

  .custom-dropdown-item {
    max-height: 20px;
    /* Ganti dengan tinggi yang Anda inginkan */
    overflow: hidden;
  }

  .typeahead-item .list-group-item {
    max-height: 10px;
    /* Ganti dengan tinggi yang Anda inginkan */
    overflow: hidden;
  }
}

.lonjong-image {
  width: 120px;
  /* Ganti dengan lebar yang Anda inginkan */
  height: 150px;
  /* Ganti dengan tinggi yang Anda inginkan */
  object-fit: cover;
  /* Menjaga proporsi gambar tetap proporsional */
}
</style>
