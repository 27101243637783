<template>
  <div class="container-fluid">
    <!-- :no-body="isMobile ? 'no-body' : ''" -->
    <b-card header="Aktifitas PMB" :no-body="isMobile">
      <div class="row">
        <div class="col-md-6 mb-2">
          <!-- <b-card> -->
          <b-input-group class="mt-0" size="sm">
            <b-form-select v-model="walas" :options="listwalas" @change="cektrans(); cektgltran()" />
            <b-input-group-append>
              <b-button variant="info" @click="getwalas" title="Refresh"><b-icon-arrow-repeat />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <!-- <b-form-select
            v-model="walas"
            :options="listwalas"
            size="sm"
            @change="cektrans()"
            class="mb-2 pt-0"
          />
          <b-icon-arrow-repeat
            @click="getwalas"
            class="position-absolute"
            style="top: -7px; right: 8px; z-index: 1; cursor: pointer"
            title="Refresh"
            size="sm"
          /> -->
          <b-card class="mt-3">Tuliskan Ket. PMB (Silabus) disini
            <b-form-textarea size="sm" v-model="silabus" />
            <b-icon-check2-circle v-if="silabus" @click="updatesilabus" class="position-absolute"
              style="top: 35px; right: 20px; z-index: 1; cursor: pointer" title="Update silabus" size="sm" />
          </b-card>
          <div class="row mt-2 mb-2">
            <div class="col-md-4">
              Tanggal PMB
              <b-input-group class="mt-2" size="sm">
                <template #append>
                  <b-input-group-text><i class="bi bi-card-list" title="List TGL" style="cursor: pointer;"
                      @click="showtgl()" /></b-input-group-text>
                </template>
                <b-form-input type="date" size="sm" v-model="tanggal" @change="cektrans()" />

              </b-input-group>


            </div>
            <!-- <b-form-select :options="listtgl" /> -->
            <div class="col-md-auto text-center mt-2 mb-2">
              PJ PMB: <b>{{ namawali }}</b> <br />
              Tahun:
              {{ tahun }} Aktivitas: <b></b>
            </div>
          </div>
          <div class="position-relative">
            Keterangan kegiatan:
            <b-form-textarea size="sm" class="mb-2 mt-2" v-model="keterangan" />
            <b-icon-check2-circle v-if="!showsave" @click="updateketerangan" class="position-absolute"
              style="top: 35px; right: 10px; z-index: 1; cursor: pointer" title="Update Keterangans" size="sm" />
          </div>
          <b-button size="sm" v-show="showsave" class="col mb-2" @click="saveall">Save Data Pertama, Untuk bisa mengisi
            data PMB</b-button>
          <b-button size="sm" class="float-right" v-show="!showsave && walas != ''"
            @click="deletefield"><b-icon-trash />
            Delete
          </b-button>
          <b-table class="table-text" :items="listsantri" :fields="['no', 'nis', 'nama', 'absen', 'rating']" size="sm"
            bordered small hover outline responsive :busy="isbusyrate">
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <!-- tbody-tr-class="align-middle" -->
            <template #head(absen)="data">
              <div style="cursor: pointer" @click="clickstatus" v-if="!showsave">
                {{ data.label }}
              </div>
            </template>
            <template #cell(no)="row">
              <div class="text-center">
                {{ row.index + 1 }}
              </div>
            </template>
            <template #cell(absen)="row">
              <div class="text-center">
                <b-form-checkbox size="sm" v-model="row.item.absen" :disabled="showsave"
                  @change="updatepmb(row.item, 'one')" value="1" unchecked-value="0" />
              </div>
            </template>
            <template #cell(rating)="row">
              <div>
                <b-form-rating size="sm" v-model="row.item.rating" :disabled="showsave"
                  @change="updatepmb(row.item, 'one')" show-clear no-border />
              </div>
            </template>
          </b-table>
          <!-- </b-card> -->
        </div>
        <div class="col-md-6">
          <Upload :nomorid="this.walas + '-' + this.tanggal" @dataupload="datauploadupdate" />
          <!-- data upload {{ datauploads }} -->

          <b-carousel id="carousel-1" v-model="slide" :interval="5000" controls indicators background="#ababab"
            style="text-shadow: 1px 1px 2px #333" img-width="1024" img-height="480" class="mt-3" img-fluid>


            <b-carousel-slide v-for="(detail, k) in datauploads" :key="k" :caption="detail.deskripsi" :text="detail.url"
              :img-src="$appUrl + detail.url">
              <!-- <video width="100%" controls autoplay>
                <source :img-src="$appUrl + detail.url">
                Browser Anda tidak mendukung pemutaran video.
              </video> -->

            </b-carousel-slide>

          </b-carousel>
        </div>
      </div>
    </b-card>
    <b-modal id="bv-modal-listtgl" hide-footer scrollabl size="lg">
      <template #modal-title>
        <b-input-group size="sm">
          <template #prepend>
            <b-input-group-text><strong class="text-danger">List Tanggal PMB
                Tahun:</strong></b-input-group-text>
          </template>
          <b-form-input v-model="year" class="col-md-6" size="sm" type="number" />
          <b-button size="sm" @click="cektgltran()">OK</b-button>
        </b-input-group> </template>
      <div class="d-block text-center">
        <!-- <b-form-select :options="listtgl" v-model="tglselect" size="sm" @change="tanggal = tglselect; cektgltran();" /> -->
        <span v-for="(tanggal, index) in listtgl" :key="index">
          <b-button size="sm" class="mr-1 mb-1" @click="gettgl(tanggal)" variant="outline-primary"> {{ tanggal
            }}</b-button>
        </span>


        <!-- {{ listtgl }} -->
      </div>
      <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-listtgl')">Close Me</b-button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Upload from "@/components/upload.vue";
// import VueCal from "vue-cal";
// import "vue-cal/dist/vuecal.css";

export default {
  name: "Aktivitaspmb",
  components: { Upload },
  data() {
    return {
      isMobile: false,
      showsave: false,
      slide: 0,
      walas: "",
      tanggal: "",
      namasantri: "",
      namawali: "",
      tahun: "",
      listwalas: [],
      listsantri: [],
      listpenilaian: [],
      listpenilaianfilter: [],
      tahun2: "",
      bulan: "",
      noasrama: "",
      nis: "",
      tgl: "01",
      kodepenilaian: "",
      isbusy: false,
      isbusyrate: false,
      datauploads: [],
      silabus: "",
      keterangan: "",
      listtgl: [], // Tanggal yang ingin ditandai
      tglselect: '',
      year: '',
    };
  },
  mounted() {
    this.tanggal = this.$func.tglhariini();
    this.year = this.tanggal.substr(0, 4);
    //  this.form.waktu = "10:00";
    this.getwalas();
    this.checkDevice();
  },
  methods: {
    gettgl(tgl) {
      console.log('gettgl', tgl)
      this.tanggal = tgl;
      this.cektrans();
      // this.$bvModal.hide('bv-modal-listtgl')
    },
    showtgl() {
      this.$bvModal.show('bv-modal-listtgl')
    },
    checkDevice() {
      // Gunakan lebar layar untuk memutuskan apakah perangkat mobile atau tidak
      this.isMobile = window.innerWidth <= 768; // Ubah nilai 768 sesuai kebutuhan Anda
      console.log("mobile device", this.isMobile);
    },
    datauploadupdate(data) {
      this.datauploads = data;
    },
    updateketerangan() {
      axios
        .get(
          this.$appUrl +
          "updateketerangan.php?token=" +
          this.$globalData.Token +
          "&userid=" +
          this.$globalData.UserId +
          "&tanggal=" +
          this.tanggal +
          "&nopmb=" +
          this.walas +
          "&ket=" +
          this.keterangan
        )
        .then((res) => {
          console.log("update keterangan Done", res.data);
        });
    },
    updatefield(val, kode, kd) {
      this.isbusy = true;
      // alert("save :" + val);
      let tahun = this.tanggal.substr(2, 2);
      let bulan = this.tanggal.substr(5, 2);
      let periode = tahun + "/" + bulan;
      axios
        .get(
          this.$appUrl +
          "updatefield.php?token=" +
          this.$globalData.Token +
          "&userid=" +
          this.$globalData.UserId +
          "&nis=" +
          this.nis +
          "&noasrama=" +
          this.noasrama +
          "&kodepenilaian=" +
          kode +
          "&periode=" +
          periode +
          "&field=" +
          this.tgl +
          "&jenis=" +
          kd +
          "&nilai=" +
          val
        )
        .then((res) => {
          console.log("Data ", res.data);
          this.isbusy = false;
        });
    },
    clickstatus() {
      //   alert("status clicked");
      for (let index = 0; index < this.listsantri.length; index++) {
        // const element = array[index];
        this.listsantri[index]["absen"] =
          this.listsantri[index]["absen"] == "1" ? 0 : 1;
      }
      // this.updatepmb("1", "2", "all");
      axios.get(
        this.$appUrl +
        "updatetranpmb.php?token=" +
        this.$globalData.Token +
        "&userid=" +
        this.$globalData.UserId +
        "&tanggal=" +
        this.tanggal +
        "&jenis=all" +
        "&nopmb=" +
        this.walas
      );
    },
    dataselect(item) {
      this.namasantri = item.nama;
      this.nis = item.nis;
      // this.gettemplate();
    },
    deletefield() {
      axios
        .get(
          this.$appUrl +
          "deletetranpmb.php?token=" +
          this.$globalData.Token +
          "&userid=" +
          this.$globalData.UserId +
          "&tanggal=" +
          this.tanggal +
          "&nopmb=" +
          this.walas
        )
        .then((res) => {
          console.log("Data ", res.data);
          this.cektrans();
          // this.isbusy = false;
        });
    },
    updatepmb(data, status) {
      axios
        .get(
          this.$appUrl +
          "updatetranpmb.php?token=" +
          this.$globalData.Token +
          "&userid=" +
          this.$globalData.UserId +
          "&nis=" +
          data.nis +
          "&rating=" +
          data.rating +
          "&tanggal=" +
          this.tanggal +
          "&absen=" +
          data.absen +
          "&jenis=" +
          status +
          "&nopmb=" +
          this.walas + "&catatan=" + ''
        )
        .then((res) => {
          console.log("Data ", res.data);
          // this.isbusy = false;
        });
    },
    getsantri() {
      // this.cektrans();
      axios
        .get(
          this.$appUrl +
          "searchwalas.php?token=" +
          this.$globalData.Token +
          "&user=" +
          this.$globalData.UserId +
          "&q=" +
          this.walas +
          "&jenis=pmb"
        )
        .then((res) => {
          // if (res.data.length != 0) {
          //   this.details = res.data;
          // }
          console.log("Data ", res.data);
          this.listsantri = res.data;
          if (this.listsantri) {
            this.noasrama = this.listsantri[0].noasrama;
            this.nis = this.listsantri[0].nis;
            this.namawali = this.listsantri[0].namawali;
            this.kelas = this.listsantri[0].kelas;
          }
        });
    },
    saveall() {
      this.showsave = false;
      axios
        .post(
          this.$appUrl +
          "savetranpmb.php?token=" +
          this.$globalData.Token +
          "&user=" +
          this.$globalData.UserId +
          "&q=" +
          this.walas +
          "&jenis=pmb+&tanggal=" +
          this.tanggal,
          { data: this.listsantri }
        )
        .then((res) => {
          console.log("Res SaveAll ", res.data);
        });
    },
    cektrans() {
      this.getsilabus();
      this.isbusyrate = true;
      console.log("pmb select", this.walas);
      axios
        .get(
          this.$appUrl +
          "cektranpmb.php?token=" +
          this.$globalData.Token +
          "&user=" +
          this.$globalData.UserId +
          "&q=" +
          this.walas +
          "&jenis=pmb+&tanggal=" +
          this.tanggal
        )
        .then((res) => {
          // if (res.data.length != 0) {
          //   this.details = res.data;
          // }
          console.log("Cektrans Data ", res.data);
          this.listsantri = res.data;
          this.isbusyrate = false;
          if (this.listsantri.length > 0) {
            this.showsave = false;
            this.noasrama = this.listsantri[0].noasrama;
            this.nis = this.listsantri[0].nis;
            this.namawali = this.listsantri[0].namawali;
            this.kelas = this.listsantri[0].kelas;
            this.keterangan = this.listsantri[0].keterangan;
          } else {
            this.keterangan = "";
            this.showsave = true;
            this.getsantri();
          }
          // this.cektgltran()
        });
    },
    cektgltran() {
      this.listtgl = [];
      axios
        .get(
          this.$appUrl +
          "listtgltran.php?token=" +
          this.$globalData.Token +
          "&user=" +
          this.$globalData.UserId +
          "&level=" +
          this.$globalData.LevelLogin +
          "&kode=" + this.walas + "&tahun=" + this.year)
        .then((res) => {
          // if (res.data.length != 0) {
          //   this.details = res.data;
          // }
          console.log("listtgl ", res.data);
          this.listtgl = res.data;
        });
    },
    updatesilabus() {
      axios.post(
        this.$appUrl +
        "updatekurikulum.php?kode=" +
        this.walas +
        "&token=" +
        this.$globalData.Token,
        this.silabus
      );
    },
    getwalas() {
      axios
        .get(
          this.$appUrl +
          "listwalas.php?token=" +
          this.$globalData.Token +
          "&user=" +
          this.$globalData.UserId +
          "&level=" +
          this.$globalData.LevelLogin +
          "&jenis=pmb"
        )
        .then((res) => {
          // if (res.data.length != 0) {
          //   this.details = res.data;
          // }
          console.log("Data ", res.data);
          this.listwalas = res.data;
        });
    },
    getsilabus() {
      const found = this.listwalas.find((item) => item.value === this.walas);
      this.silabus = found ? found.keterangan : null;
    },
  },
};
</script>

<style scoped>
.marked-date {
  border-color: red !important;
  /* Warna border merah untuk tanggal yang ditandai */
  background-color: #ffcccc !important;
  /* Background merah muda */
}

.gradient {
  background: linear-gradient(to bottom, #134a6e 33%, #66ff99 100%);
}

.pointer {
  cursor: pointer;
}

.align-middle>td {
  vertical-align: middle;
}

@media screen and (min-width: 601px) {
  .table-text {
    font-size: 14px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .table-text {
    font-size: 12px;
  }
}
</style>
