<template>
  <div class="container-fluid">
    <b-card>
      <!-- <div @click="refresh('2')">Uang JAJAN Santri:</div> -->
      <template #header>
        <h4 class="mb-0">Uang JAJAN Santri</h4>
      </template>
      <div class="row">
        <div class="col-md-6 border-right">
          <div class="row">
            <div class="col-md-8">
              <b-input-group size="sm">
                <template #append>
                  <b-button variant="outline-success" @click="refresh(search)">
                    <b-icon-search />
                  </b-button><span class="mt-2 ml-2" style="font-size: 13px">#{{ itemsWithTotal.length - 1
                    }}</span>
                </template>
                <b-form-input placeholder="cari nama santri" v-model="search" @input="query = search"
                  @keydown.enter="refresh(search)"></b-form-input>
              </b-input-group>

            </div>
            <div class="col">
              <b-button size="sm" class="float-right" @click.stop="trans('+', '', '')" title="Penambahan Uang">
                <i class="bi bi-file-plus" /></b-button>
            </div>
          </div>
          <b-table hover class="mt-1" :fields="['no', 'nis', 'nama', 'saldo', 'aksi']" :items="itemsWithTotal"
            @row-clicked="dataselected" responsive style="font-size: 14px" :tbody-tr-class="rowClass" :busy="isbusy"
            sticky-header="500px" small>
            <template #cell(aksi)="row">
              <div style="cursor: pointer" v-show="row.item.nis != ''">
                <i class="bi bi-file-minus" title="Pengambilan" @click.stop="
                  trans('-', row.item.nis, row.item.nama, row.item.saldo)
                  "></i>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(no)="row">
              <div v-show="row.item.nis != ''">
                {{ row.index + 1 }}
              </div>
            </template>
            <template #head(saldo)="row">
              <div class="text-right">
                {{ row.label }}
              </div>
            </template>
            <template #cell(saldo)="row">
              <div class="text-right" v-show="row.item.nis == ''">
                <b>{{ $func.NumberFormat(row.item.saldo) }}</b>
              </div>
              <div class="text-right" v-show="row.item.nis != ''">
                {{ $func.NumberFormat(row.item.saldo) }}
              </div>
            </template>
          </b-table>
          <!-- {{ itemsWithTotal }} -->
        </div>
        <div class="col-md-6">
          Transaksi an <b>{{ namasantri }}</b>
          <b-table hover class="mt-2" :fields="['nis', 'tanggal', 'jenis', 'jumlah', 'iden']" :items="itemtotaltran"
            responsive style="font-size: 14px" :busy="isbusy2" small>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #head(jumlah)="row">
              <div class="text-right">
                {{ row.label }}
              </div>
            </template>
            <template #cell(jumlah)="row">
              <div class="text-right">
                {{ $func.NumberFormat(row.item.jumlah) }}
              </div>
            </template>
            <template #cell(iden)="row">
              <div class="text-center" v-show="row.item.nis != ''">
                {{ row.item.identify }}
                <b-icon-trash @click="deletetran(row.item.identify)" title="Delete Transaksi" style="cursor: pointer" />
              </div>
            </template>
          </b-table>
          <!-- {{ transuangjajan }} -->
        </div>
      </div>
      <!-- {{ jenis }} -->
      <!-- {{ datauangjajan }} -->
      <!-- <b-button size="sm" @click="refresh()" class="mr-2">Refresh</b-button> -->
    </b-card>

    <b-modal v-model="showModal" id="dodols" @shown="onModalShown" :title="judul" hide-footer no-close-on-backdrop>
      <div class="row mb-0" size="sm">
        <div class="col-md-8">
          Nama Siswa:
          <vue-bootstrap-typeahead ref="typeahead" class="mb-4" size="sm" v-model="query" :data="users"
            :serializer="(item) => item.nama" @hit="
              selectedUser = $event;
            noasrama = selectedUser.noasrama;
            kelas = selectedUser.nokelas;
            " placeholder="pencarian nama" />
        </div>

        <div class="col-md mt-1"><br />NIS:{{ selectedUser.nis }}</div>
        <!-- {{ form.nis }},{{ selectedUser }} -->
      </div>
      <div class="row">
        <div class="col-md-auto">
          <b-img :src="'https://apps.baiturrahman.com/php.script/' + selectedUser.pasfoto
            " class="lonjong-image" rounded width="80%" />
        </div>
        <div class="col-md text-left">
          <div>
            Nama Wali <b>{{ selectedUser.namawali }}</b> <br />
            Asrama
            <b>{{ selectedUser.namaasrama }} {{ selectedUser.noasrama }}</b><br />
            NoHP Ortu <b>{{ selectedUser.nohp }}</b> <br />
            Kelas
            <b>{{ selectedUser.nokelas }}, {{ selectedUser.namakelas }}</b>
          </div>
        </div>
        <div class="row mt-2 px-3">
          <div class="col-md-6">
            <b-input-group size="sm" prepend="Tgl Trans">
              <b-form-input type="date" size="sm" v-model="tanggal" /></b-input-group>
          </div>
          <div class="col-md-6">
            <b-input-group size="sm" :prepend="judulisian">
              <money v-model="jumlah" v-bind="money" size="sm" class="form-control form-control-sm"
                style="text-align: right" />

              <!-- <b-form-input
                type="number"
                size="sm"
                v-model="jumlah"
                class="text-right"
            /> -->
            </b-input-group>
          </div>
        </div>
      </div>
      <b-button class="mt-3" size="sm" block @click="savetrans">Submit</b-button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  name: "Uangjajan",
  components: {},
  data() {
    return {
      nis: "",
      jenis: "",
      tanggal: "",
      jumlah: 0,
      query: "",
      users: [],
      datauangjajan: [],
      transuangjajan: [],
      search: "",
      selectedUser: {},
      selectedrow: "",
      namasantri: "",
      isbusy: false,
      isbusy2: false,
      showModal: false,
      max: 0,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
    };
  },
  computed: {
    judul() {
      return this.jenis == "-" ? "Form Uang KELUAR" : "Form Uang MASUK";
    },
    judulisian() {
      return this.jenis == "-" ? "Jumlah KELUAR" : "Jumlah MASUK";
    },
    itemtotaltran() {
      const totalGapok = this.transuangjajan.reduce((total, item) => {
        if (item.jenis == "+") {
          return total + parseFloat(item.jumlah);
        } else {
          return total - parseFloat(item.jumlah);
        }
      }, 0);

      // Menambahkan baris total ke data items
      return [
        ...this.transuangjajan,
        {
          nis: "",
          tanggal: "TOTAL UANG",
          jenis: "",
          isTotal: true,
          jumlah: totalGapok,
          iden: "",
        },
      ];
    },
    itemsWithTotal() {
      // Menghitung total gapok
      const totalGapok = this.datauangjajan.reduce(
        (total, item) => total + parseFloat(item.saldo),
        0
      );
      // Menambahkan baris total ke data items
      return [
        ...this.datauangjajan,
        {
          nis: "",
          nama: "TOTAL SALDO",
          saldo: totalGapok,
          isTotal: true,
          aksi: "",
        },
      ];
    },
  },
  mounted() {
    this.tanggal = this.$func.tglhariini();
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    query: _.debounce(function (newQuery) {
      this.searchUsers(newQuery);
    }, 500),
    search: _.debounce(function (newQuery) {
      this.refresh(newQuery);
    }, 500),
  },
  methods: {
    deletetran(id) {
      this.$bvModal
        .msgBoxConfirm("ID " + id + " Yakin untuk di Hapus ?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post(
                this.$appUrl +
                "deletetransuang.php?user=" +
                this.$globalData.UserId +
                "&token=" +
                this.$globalData.Token,
                { id: id }
              )
              .then((res) => {
                console.log(res);
                this.refresh();
              });
          }
        });
    },
    onModalShown() {
      console.log("noModalShown");
      if (this.$refs.typeahead) {
        this.$refs.typeahead.inputValue = this.namasantri;
      } else {
        console.error("Typeahead ref is not available.");
      }
    },
    rowClass(item, type) {
      // console.log("item", item.nodisiplin, this.selectedrow);
      if (!item || type !== "row") return;
      if (item.nis === this.selectedrow) return "table-success";
    },
    trans(sign, nis, nama, max) {
      console.log("trans ", sign, nis);
      this.nis = nis;
      this.jenis = sign;
      this.namasantri = nama;
      this.max = max;
      console.log("refs", this.$refs);
      this.jumlah = 0;
      if (sign == "-") {
        this.searchUsers2(nis);
      } else {
        this.selectedUser = {};
      }
      // this.query = nis;
      this.showModal = true;
      // this.$refs.typeahead.inputValue = nis;
    },
    savetrans() {
      if (this.jumlah == 0) {
        return "";
      }
      if (this.jenis == "-") {
        if (this.jumlah > this.max) {
          this.$bvModal.msgBoxOk("Nilai Uang melebihi Saldo");
          return "";
        }
      }
      this.$bvModal
        .msgBoxConfirm("Yakin untuk di Submit ?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post(
                this.$appUrl +
                "savetransuang.php?user=" +
                this.$globalData.UserId +
                "&token=" +
                this.$globalData.Token,
                {
                  nis: this.selectedUser.nis,
                  tanggal: this.tanggal,
                  jumlah: this.jumlah,
                  jenis: this.jenis,
                }
              )
              .then((resp) => {
                console.log("save tran", resp);
                this.refresh();
                this.showModal = false;
              });
          }
        });
    },
    refresh() {
      this.isbusy = true;
      axios
        .get(
          this.$appUrl +
          "getuangjajan.php?token=" +
          this.$globalData.Token +
          "&q=" +
          this.search
        )
        .then((response) => {
          this.transuangjajan = [];
          this.namasantri = "";
          this.datauangjajan = response.data;
          this.isbusy = false;
          //  this.totsaldo = this.datauangjajan.reduce(
          //    (total, item) => total + item.saldo,
          //    0
          //  );
        });
    },
    searchUsers2(newQuery) {
      axios
        .get(
          this.$appUrl +
          `searchdisiplin2.php?q=${newQuery}` +
          "&token=" +
          this.$globalData.Token
        )
        .then((res) => {
          this.selectedUser = res.data[0];
        });
    },

    searchUsers(newQuery) {
      axios
        .get(
          this.$appUrl +
          `searchuangjajan.php?q=${newQuery}` +
          "&token=" +
          this.$globalData.Token
        )
        .then((res) => {
          this.users = res.data;
        });
    },
    dataselected(item) {
      if (item.nis == "") {
        return "";
      }
      this.isbusy2 = true;

      this.selectedrow = item.nis;
      this.namasantri = item.nama;
      console.log("selected ", item);
      axios
        .get(
          this.$appUrl +
          "gettranuangjajan.php?token=" +
          this.$globalData.Token +
          "&nis=" +
          item.nis
        )
        .then((res) => {
          this.transuangjajan = res.data;
          this.isbusy2 = false;
        });
    },
    updatenotes() {
      axios.post(this.$appUrl + "updatenotes.php", { data: this.notes });
    },
  },
};
</script>

<style>
#editor1 {
  height: 450px;
}
</style>
