import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/components/home.vue";
import Mynotes from "@/components/mynotes.vue";
import Keuangan from "@/components/uangjajan.vue";
import Setting from "@/aplikasi/setting.vue";
import Datasiswa from "@/aplikasi/datasiswa.vue";
import Setting2 from "@/aplikasi/setting2.vue";
import Dashboard from "@/aplikasi/dashboard.vue";
import Dashboardadmin from "@/aplikasi/dashboardadmin.vue";
import Konseling from "@/aplikasi/konseling.vue";
import Ngobras from "@/aplikasi/ngobras.vue";
import Disiplin from "@/aplikasi/disiplin.vue";
import Pengobatan from "@/aplikasi/pengobatan.vue";
import Penilaian from "@/aplikasi/penilaian.vue";
import Aktivitaspmb from "@/aplikasi/aktivitaspmb.vue";
import Murajaah from "@/aplikasi/murajaah.vue";
import Adminizin from "@/aplikasi/adminizin.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/keuangan",
    name: "Keuangan",
    component: Keuangan,
  },
  {
    path: "/murajaah",
    name: "Murajaah",
    component: Murajaah,
  },
  {
    path: "/adminizin",
    name: "Adminizin",
    component: Adminizin,
  },

  {
    path: "/mynotes",
    name: "Mynotes",
    component: Mynotes,
  },
  {
    path: "/konseling/:id",
    name: "Konseling",
    component: Konseling,
  },
  {
    path: "/ngobras",
    name: "Ngobras",
    component: Ngobras,
  },
  {
    path: "/pengobatan",
    name: "Pengobatan",
    component: Pengobatan,
  },
  {
    path: "/penilaian",
    name: "Penilaian",
    component: Penilaian,
  },
  {
    path: "/aktivitaspmb",
    name: "Aktivitaspmb",
    component: Aktivitaspmb,
  },
  {
    path: "/disiplin",
    name: "Disiplin",
    component: Disiplin,
  },
  {
    path: "/dashboardadmin",
    name: "Dashboardadmin",
    component: Dashboardadmin,
  },
  {
    path: "/datasiswa",
    name: "Datasiswa",
    component: Datasiswa,
  },
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
  },
  {
    path: "/setting2",
    name: "Setting2",
    component: Setting2,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  // {
  //    path: '/about',
  //    name: 'About',
  //    // route level code-splitting
  //    // this generates a separate chunk (about.[hash].js) for this route
  //    // which is lazy-loaded when the route is visited.
  //    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "hash",
  //	base: process.env.BASE_URL,
  base: process.env.NODE_ENV === "development" ? "/" : "/inward/",
  routes,
});

export default router;
