<template>
  <div class="container-fluid">
    <div class="row">
      <Showdata2 namatable="kelas" judul="Data Kelas" />
    </div>
    <div class="row">
      <Showdata2 namatable="pmb" judul="Data PMB" />
    </div>
    <div class="row">
      <Showdata2 namatable="asrama" judul="Data Asrama" />
    </div>

    <div class="fluid">
      <b-card class="mt-4">
        <template #header>
          <h4>Merubah Periode dan Penambahan PMB,Kelas dan Asrama</h4>
        </template>
        <b-card-text>
          <div class="row">
            <div class="col-md-4">
              <b-input-group prepend="Periode Baru" class="mt-3" size="sm">
                <b-form-input v-model="form.kode" placeholder="ex. 2425" />
                <b-input-group-append>
                  <b-button variant="outline-success" @click="goproses('periode')">Go Proses</b-button>
                </b-input-group-append>

              </b-input-group>Periode Aktif {{ $globalData.CurrentYear }}
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-10">
              <b-link @click="show('pmb')">Penambahan Data PMB {{ $globalData.CurrentYear }}</b-link>
              <b-input-group prepend="Kode | Nama PMB" class="mt-3" size="sm">
                <b-form-input v-model="form.kodepmb" class="col-1" placeholder="isi kode" />
                <b-form-input v-model="form.namapmb" class="col-4" placeholder="isi Nama PMB" />
                <b-input-group-append>
                  <b-button variant="outline-success" @click="goproses('pmb')">Go Proses</b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
          <hr />

          <div class="row">
            <div class="col-md-10">
              <b-link @click="show('kelas')">Penambahan Data KELAS {{ $globalData.CurrentYear }}</b-link>
              <b-input-group prepend="Kode | Nama KELAS | Jenjang | Kelas" class="mt-3" size="sm">
                <b-form-input v-model="form.kodekelas" class="col-1" placeholder="isi kode" />
                <b-form-input v-model="form.namakelas" class="col-4" placeholder="isi Nama KELAS" />
                <b-form-select v-model="form.jenjang" class="col-1"
                  :options="[{ text: 'SMP', value: 'SMP' }, { text: 'SMA', value: 'SMA' }]" />
                <b-form-select v-model="form.kelas" class="col-1" :options="[{ text: '07', value: '07' },
                { text: '08', value: '08' }, { text: '09', value: '09' }, { text: '10', value: '10' },
                { text: '11', value: '11' }, { text: '12', value: '12' }]" />
                <b-input-group-append>
                  <b-button variant="outline-success" @click="goproses('kelas')">Go Proses</b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
          <hr />

          <div class="row">
            <div class="col-md-10">
              <b-link @click="show('asrama')">Penambahan Data ASRAMA {{ $globalData.CurrentYear }}</b-link>
              <b-input-group prepend="Kode | Nama ASRAMA | L/P" class="mt-3" size="sm">
                <b-form-input v-model="form.kodeasrama" class="col-1" placeholder="isi kode" />
                <b-form-input v-model="form.namaasrama" class="col-4" placeholder="isi Nama Asrama" />
                <b-form-select v-model="form.jenis" class="col-1"
                  :options="[{ text: 'L', value: 'L' }, { text: 'P', value: 'P' }]" />
                <b-input-group-append>
                  <b-button variant="outline-success" @click="goproses('asrama')">Go Proses</b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </b-card-text>
      </b-card>
    </div>
    <b-modal id="bv-modal-example" hide-footer scrollable size="lg">
      <template #modal-title>
        Data Master {{ judul }}
      </template>
      <b-table :items="listmaster" style="font-size: 13px;" :busy="isbusy" sticky-header="400px">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(namapmb)="row">
          <div class="row">
            <b-form-input v-model="row.item.namapmb" size="sm" class="col-10" />
            <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
              @click="updatemaster(row.item, 'pmb')" />
          </div>
        </template>
        <template #cell(namapmb)="row">
          <div class="row">
            <b-form-input v-model="row.item.namapmb" size="sm" class="col-10" />
            <b-icon-arrow-repeat title="Update Row ini" style="cursor: pointer" class="mt-2 ml-1"
              @click="updatemaster(row.item, 'pmb')" />
          </div>
        </template>
        <template #cell(namaasrama)="row">
          <div class="row">
            <b-form-input v-model="row.item.namaasrama" size="sm" class="col-10" />
            <b-icon-arrow-repeat title="Update Row ini" style="cursor: pointer" class="mt-2 ml-1"
              @click="updatemaster(row.item, 'asrama')" />
          </div>
        </template>
        <template #cell(jenisasrama)="row">
          <b-form-input v-model="row.item.jenisasrama" size="sm" class="col-2" />
        </template>
        <template #cell(namakelas)="row">
          <div class="row">
            <b-form-input v-model="row.item.namakelas" size="sm" class="col-10" />
            <b-icon-arrow-repeat title="Update Row ini" style="cursor: pointer" class="mt-2 ml-1"
              @click="updatemaster(row.item, 'kelas')" />
          </div>
        </template>
        <template #cell(jenjang)="row">
          <b-form-input v-model="row.item.jenjang" size="sm" class="col-3" />
        </template>
        <template #cell(jenjangkelas)="row">
          <b-form-input v-model="row.item.jenjangkelas" size="sm" class="col-3" />
        </template>


      </b-table>
      <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Close Me</b-button>
    </b-modal>
  </div>

</template>

<script>
import axios from "axios";
import Showdata2 from "@/aplikasi/showdata2.vue";

export default {
  name: "Setting2",
  components: { Showdata2 },
  data() {
    return {
      datapmb: [],
      listmaster: [],
      isbusy: false,
      form: {
        kode: '',
        kodepmb: '',
        kodeasrama: '',
        kodekelas: '',
        namakelas: '',
        namaasrama: '',
        namapmb: '',
        jenjang: 'SMP',
        kelas: '07',
        jenis: 'L',
      },
      judul: '',
    };
  },
  mounted() {
    this.refresh("datapmb");
  },
  methods: {
    updatemaster(data, kode) {
      console.log("Log", data, kode)
      axios
        .post(this.$appUrl +
          "updatemaster.php?token=" +
          this.$globalData.Token +
          "&para=" +
          kode + "&periode=" + this.$globalData.CurrentYear, data)
        .then((res) => {
          console.log(res.data)
        })
    },
    goproses(para) {
      //alert(JSON.stringify(this.form) + para)
      this.$bvModal
        .msgBoxConfirm("Yakin Untuk di Submit?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post(this.$appUrl +
                "savemaster.php?token=" +
                this.$globalData.Token +
                "&para=" +
                para + "&periode=" + this.$globalData.CurrentYear, this.form)
              .then((res) => {
                console.log(res.data)
              })
          }
        })
    },
    show(para) {
      // alert(para)
      this.isbusy = true
      this.judul = para.toUpperCase()
      axios
        .get(
          this.$appUrl +
          "showmaster.php?token=" +
          this.$globalData.Token +
          "&para=" +
          para
        )
        .then((res) => {
          this.$bvModal.show('bv-modal-example')
          this.isbusy = false

          this.listmaster = res.data;

        });
    },
    refresh(para) {
      axios
        .get(
          this.$appUrl +
          "showdata.php?token=" +
          this.$globalData.Token +
          "&para=" +
          para
        )
        .then((res) => {
          this.datapmb = res.data;
        });
    },
  },
};
</script>

<style>
.gradient {
  background: linear-gradient(to bottom, #134a6e 33%, #66ff99 100%);
}
</style>
