<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 mb-2">
        <b-card>
          <template #header>
            <h4 class="mb-0">List Ngobras</h4>
          </template>
          <div class="row">
            <div class="col-md-6">
              <b-input-group size="sm" class="mb-2">
                <b-form-input
                  v-model="search"
                  placeholder="pencarian topik"
                  @keyup.enter="getdata()"
                />
                <b-input-group-append is-text variant="primary">
                  <b-icon-search
                    size="sm"
                    style="cursor: pointer"
                    @click="getdata()"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-md-6">
              <div class="row">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  @input="getdata()"
                  size="sm"
                ></b-pagination>
                <span style="font-size: 12px" class="mt-2 ml-2"
                  >#{{ totalRows }}</span
                >
              </div>
            </div>
          </div>
          <!-- total {{ totalRows }} -->
          <b-table
            hover
            :fields="[
              'no',
              'nongobras',
              'tanggal',
              'waktu                                                     ',
              'topik',
              'deskripsi',
            ]"
            :items="datangobras"
            @row-clicked="dataselected"
            responsive
            style="font-size: 14px"
            :tbody-tr-class="rowClass"
          >
            <template #cell(no)="row">
              {{ row.index + (currentPage - 1) * perPage + 1 }}
            </template>
          </b-table></b-card
        >
      </div>
      <div class="col-md-6">
        <b-card>
          <template #header>
            <div>
              <h4 class="mb-0">
                Data Ngobras (
                {{ form.nongobras }})
              </h4>
            </div>
          </template>
          <b-form @submit="onSubmit" @reset="onReset">
            <div class="row mb-2" size="sm">
              <div class="col">
                Tanggal:
                <b-form-input type="date" v-model="form.tanggal" required />
              </div>
              <div class="col">
                Waktu:
                <b-form-input type="time" v-model="form.waktu" required />
                <!-- {{ form.waktu }} -->
              </div>
            </div>
            Tempat:
            <b-form-input class="mb-2" v-model="form.tempat" required />
            Topik:
            <b-form-input class="mb-2" v-model="form.topik" required />
            Deskripsi:
            <b-form-textarea class="mb-2" v-model="form.deskripsi" required />
            Catatan/Resume
            <b-form-textarea class="mb-2" v-model="form.catatan" required />
            <b-button
              type="submit"
              variant="primary"
              size="sm"
              class="mr-2 mb-2"
              >{{ isnew ? "Submit" : "Update" }}</b-button
            >
            <b-button type="reset" variant="danger" size="sm" class="mr-2 mb-2"
              >Reset</b-button
            >
            Mode: {{ isnew ? "New" : "Edit" }}
            <!-- {{ form }} -->
            <hr />
            <Upload :nomorid="form.nongobras" />
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Upload from "@/components/upload.vue";

export default {
  name: "Ngobras",
  components: { Upload },
  data() {
    return {
      datangobras: [],
      listupload: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 15,
      search: "",
      isnew: true,
      deskripsi: "",
      dok: null,
      selectedrow: "",
      form: {
        identify: "",
        tanggal: "",
        tempat: "",
        waktu: "",
        topik: "",
        deskripsi: "",
        catatan: "",
        nongobras: "",
        kodewali: "",
      },
    };
  },
  mounted() {
    this.form.tanggal = this.$func.tglhariini();
    this.form.waktu = "10:00";
    this.getdata();
  },
  methods: {
    onReset(event) {
      event.preventDefault();
      // this.form = {};
      // this.form.nongobras = "";
      this.isnew = true;
      this.form = {
        identify: 0,
        tempat: "",
        topik: "",
        deskripsi: "",
        catatan: "",
        nongobras: "",
        kodewali: "",
      };
    },

    dataselected(item) {
      console.log("item ", item);
      this.form.identify = item.identify;
      this.form.tanggal = item.tanggal;
      this.form.tempat = item.tempat;
      this.form.waktu = item.waktu;
      this.form.topik = item.topik;
      this.form.deskripsi = item.deskripsi;
      this.form.catatan = item.catatan;
      this.form.nongobras = item.nongobras;
      this.form.kodewali = item.kodewali;
      this.isnew = false;
      this.selectedrow = item.nongobras;
      // alert("masuk doubleklik");
    },
    rowClass(item, type) {
      // if (!item || type !== "row") return;
      // console.log("rowclas", item.kode, this.selectedrow);
      if (item.nongobras === this.selectedrow) {
        console.log("OK", type);
      }
      if (item.nongobras === this.selectedrow) return "table-success";
    },
    getdata() {
      axios
        .get(
          this.$appUrl +
            "getngobras.php?token=" +
            this.$globalData.Token +
            "&page=" +
            this.currentPage +
            "&perpage=" +
            this.perPage +
            "&jmlrow=" +
            this.totalRows +
            "&q=" +
            this.search +
            "&level=" +
            this.$globalData.LevelLogin +
            "&userid=" +
            this.$globalData.UserId
        )
        .then((res) => {
          // if (res.data.length != 0) {
          //   this.details = res.data;
          // }
          console.log("Data ", res.data);
          this.datangobras = res.data.records;
          if (this.currentPage == 1) {
            this.totalRows = res.data.jumlahrecord;
          }
          console.log(
            "rows",
            this.totalRows,
            this.datangobras,
            res.data.records
          );
        });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Yakin untuk di Simpan ?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post(
                this.$appUrl +
                  "savengobras.php?token=" +
                  this.$globalData.Token +
                  "&isnew=" +
                  this.isnew +
                  "&username=" +
                  this.$globalData.UserId,
                { data: this.form },
                {
                  crossDomain: true,
                }
              )
              .then((res) => {
                console.log("data", res.data);
                if (this.isnew == 1) {
                  this.form.nongobras = res.data[0].nongobras;
                }
                this.$toast.success(
                  this.isnew == 1 ? "Sukses Save" : "Sukses Update",
                  {
                    type: "success",
                    position: "top-right",
                    duration: 3000,
                    dismissible: true,
                  }
                ); //   output.className = 'container';
                this.isnew = 0;
                // this.refresh("2");
              });
          }
        });
    },
  },
};
</script>

<style>
.gradient {
  background: linear-gradient(to bottom, #134a6e 33%, #66ff99 100%);
}
</style>
