<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 mb-2">
        <b-card>
          <template #header>
            <h4 class="mb-0">List Konseling {{ parameter }}</h4>
          </template>
          <div class="row">
            <div class="col-md-6">
              <b-input-group size="sm" class="mb-2">
                <b-form-input v-model="search" placeholder="pencarian topik" @keyup.enter="getdata()" />
                <b-input-group-append is-text variant="primary">
                  <b-icon-search size="sm" style="cursor: pointer" @click="getdata()" />
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-md-6">
              <div class="row">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"
                  @input="getdata()" size="sm"></b-pagination>
                <span style="font-size: 12px" class="mt-2 ml-2">#{{ totalRows }}</span>
              </div>
            </div>
          </div>
          <!-- total {{ totalRows }} -->
          <b-table hover :fields="[
            'no',
            'nokonseling',
            'tanggal',
            'nama',
            'jenis',
            'status',
          ]" :items="datadisiplin" @row-clicked="dataselected" responsive style="font-size: 14px"
            :tbody-tr-class="rowClass">
            <template #cell(no)="row">
              {{ row.index + (currentPage - 1) * perPage + 1 }}
            </template>
          </b-table>
        </b-card>
      </div>
      <div class="col-md-6">
        <b-card>
          <template #header>
            <div>
              <h4 class="mb-0">
                Data Konseling {{ parameter }} ({{ form.nokonseling }})
              </h4>
            </div>
          </template>
          <b-form @submit="onSubmit" @reset="onReset">
            <div class="row mb-0" size="sm">
              <div class="col-md-8">
                Nama Siswa:
                <vue-bootstrap-typeahead class="mb-4" size="sm" ref="typeahead" v-model="query" :data="users"
                  :serializer="(item) => item.nama" @hit="
                    selectedUser = $event;
                  form.nis = selectedUser.nis;
                  form.noasrama = selectedUser.noasrama;
                  form.kelas = selectedUser.nokelas;
                  " placeholder="pencarian nama" />
              </div>

              <div class="col-md mt-1"><br />NIS:{{ selectedUser.nis }}</div>
              <!-- {{ form.nis }},{{ selectedUser }} -->
            </div>
            <div class="row">
              <div class="col-md-auto">
                <b-img :src="$appUrl + selectedUser.pasfoto" class="lonjong-image" rounded />
              </div>
              <div class="col-md text-left">
                <div>
                  Nama Wali <b>{{ selectedUser.namawali }}</b> <br />
                  Asrama
                  <b>{{ selectedUser.namaasrama }}
                    {{ selectedUser.noasrama }}</b><br />
                  NoHP Ortu <b>{{ selectedUser.nohp }}</b> <br />
                  Kelas
                  <b>{{ selectedUser.nokelas }}, {{ selectedUser.namakelas }}</b>
                </div>
              </div>
            </div>
            <div class="row mt-2 mb-2">
              <div class="col-md">
                Tanggal:
                <b-form-input type="date" v-model="form.tanggal" required size="sm" />
              </div>
              <div class="col-md">
                Jam:
                <b-form-input type="time" v-model="form.waktu" required size="sm" />
              </div>
              <div class="col-md">
                Kategori:
                <b-form-select v-model="form.kategori" class="col" size="sm"
                  style="background-color: white; font-weight: bold" :options="[
                    { value: 'ringan', text: 'ringan' },
                    { value: 'sedang', text: 'Sedang' },
                    { value: 'berat', text: 'Berat' },
                  ]" />
                <!-- {{ form.waktu }} -->
              </div>
            </div>
            Jenis Koseling:
            <b-form-select class="mb-0 mb-2" v-model="form.jeniskonseling" :options="listdisiplin" size="sm" required />
            Topik:
            <b-form-input class="mb-2" v-model="form.topik" required size="sm" />
            Deskripsi:
            <b-form-textarea class="mb-2" v-model="form.deskripsi" required size="sm" />

            <div class="row justify-content-between ml-2">
              <div>
                <b-button type="submit" variant="primary" size="sm" class="mr-2 mb-2">{{ isnew ? "Submit" : "Update"
                  }}</b-button>
                <b-button type="reset" variant="danger" size="sm" class="mr-2 mb-2">Reset</b-button>
                Mode: {{ isnew ? "New" : "Edit" }}
              </div>
              <b-input-group prepend="Status" class="mb-2 col-md-4 float-right" size="sm">
                <b-form-select v-model="form.status" size="sm" style="background-color: white; font-weight: bold"
                  :options="[
                    { value: '0', text: 'Open' },
                    { value: '1', text: 'Closed' },
                  ]" /></b-input-group>
            </div>
            <!-- {{ form }} -->
            <hr />
            <Upload :nomorid="form.nokonseling" />
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Upload from "@/components/upload.vue";
import _ from "lodash";

export default {
  name: "Konseling",
  components: { Upload },
  data() {
    return {
      parameter: this.$route.params.id,
      options: {},
      selectedCountry: null,
      datadisiplin: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 15,
      search: "",
      isnew: true,
      deskripsi: "",
      dok: null,
      listdisiplin: [],
      form: {
        identify: "",
        status: "0",
        nis: "",
        unik: "",
        tanggal: "",
        jeniskonseling: "00",
        tahun: "",
        userid: "",
        noasrama: "",
        nokonseling: "",
        kelas: "",
        catatankonseling: "",
        topik: "",
        kategori: "ringan",
      },
      selectedItem: "",
      suggestionsList: [],
      query: "",
      users: [],
      selectedUser: {},
      selectedrow: "",
    };
  },
  mounted() {
    this.form.tanggal = this.$func.tglhariini();
    this.form.waktu = "10:00";
    this.getdata();
    axios
      .get(
        this.$appUrl + `listkonseling.php` + "?token=" + this.$globalData.Token
      )
      .then((res) => {
        this.listdisiplin = res.data;
      });
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    "$route.params.id": function (value) {
      this.parameter = value == "bk" ? "BK" : "WL";
      this.getdata();
      this.onReset();
    },
    querys(newQuery) {
      axios
        .get(
          this.$appUrl +
          `searchdisiplin.php?q=${newQuery}` +
          "&token=" +
          this.$globalData.Token
        )
        .then((res) => {
          this.users = res.data;
        });
    },
    query: _.debounce(function (newQuery) {
      this.searchUsers(newQuery);
    }, 500),
  },

  methods: {
    rowClass(item, type) {
      // if (!item || type !== "row") return;
      // console.log("rowclas", item.kode, this.selectedrow);
      if (item.nokonseling === this.selectedrow) {
        console.log("OK", type);
      }
      if (item.nokonseling === this.selectedrow) return "table-success";
    },
    searchUsers(newQuery) {
      axios
        .get(
          this.$appUrl +
          `searchdisiplin.php?q=${newQuery}` +
          "&token=" +
          this.$globalData.Token + "&level=" + this.$globalData.LevelLogin + "&user=" + this.$globalData.UserId
        )
        .then((res) => {
          this.users = res.data;
        });
    },
    onReset() {
      // event.preventDefault();

      this.isnew = true;
      //   return 0;

      // this.form = {
      this.form.identify = 0;
      this.form.nis = "";
      this.form.unik = "";
      this.form.tahun = "";
      this.form.nokonseling = "";
      this.form.topik = "";
      this.form.deskripsi = "";
      this.form.nama = "";
      this.form.status = 0;
      // };

      this.form.this.$refs.typeahead.inputValue = "";
      this.selectedUser = {};
    },

    dataselected(item) {
      console.log("item selected ", item);
      this.isnew = 0;
      this.form = {
        identify: item.identify,
        nis: item.nis,
        unik: item.unik,
        tanggal: item.tanggal,
        waktu: item.waktu,
        jeniskonseling: item.jeniskonseling,
        tahun: item.tahun,
        kategori: item.kategori,
        userid: item.userid,
        kelas: item.kelas,
        noasrama: item.noasrama,
        nokonseling: item.nokonseling,
        topik: item.topik,
        deskripsi: item.deskripsi,
        nama: item.nama,
        status: item.status,
      };
      this.$refs.typeahead.inputValue = item.nama;
      this.selectedUser.nis = item.nis;
      this.selectedUser.namaasrama = item.namaasrama;
      this.selectedUser.noasrama = item.noasrama;
      this.selectedUser.nohp = item.nohp;
      this.selectedUser.nokelas = item.kelas;
      this.selectedUser.namawali = item.namawali;
      this.selectedUser.pasfoto = item.pasfoto;
      this.selectedUser.namakelas = item.namakelas;
      this.selectedrow = item.nokonseling;
      // alert("masuk doubleklik");
    },
    getdata() {
      axios
        .get(
          this.$appUrl +
          "getkonseling.php?token=" +
          this.$globalData.Token +
          "&page=" +
          this.currentPage +
          "&perpage=" +
          this.perPage +
          "&jmlrow=" +
          this.totalRows +
          "&q=" +
          this.search +
          "&para=" +
          this.parameter +
          "&level=" +
          this.$globalData.LevelLogin +
          "&userid=" +
          this.$globalData.UserId
        )
        .then((res) => {
          // if (res.data.length != 0) {
          //   this.details = res.data;
          // }
          console.log("Data ", res.data);
          this.datadisiplin = res.data.records;
          if (this.currentPage == 1) {
            this.totalRows = res.data.jumlahrecord;
          }
          console.log(
            "rows",
            this.totalRows,
            this.datadisiplin,
            res.data.records
          );
        });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Yakin untuk di Simpan ?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post(
                this.$appUrl +
                "savekonseling.php?token=" +
                this.$globalData.Token +
                "&isnew=" +
                this.isnew +
                "&username=" +
                this.$globalData.UserId +
                "&para=" +
                this.parameter,
                { data: this.form },
                {
                  crossDomain: true,
                }
              )
              .then((res) => {
                console.log("data", res.data);
                if (this.isnew == 1) {
                  this.form.nokonseling = res.data[0].nokonseling;
                }
                this.$toast.success(
                  this.isnew == 1 ? "Sukses Save" : "Sukses Update",
                  {
                    type: "success",
                    position: "top-right",
                    duration: 3000,
                    dismissible: true,
                  }
                ); //   output.className = 'container';
                this.isnew = 0;
                // this.refresh("2");
              });
          }
        });
    },
    searchItems() {
      console.log("search item");
    },
  },
};
</script>

<style>
.gradient {
  background: linear-gradient(to bottom, #134a6e 33%, #66ff99 100%);

  .custom-dropdown-item {
    max-height: 20px;
    /* Ganti dengan tinggi yang Anda inginkan */
    overflow: hidden;
  }

  .typeahead-item .list-group-item {
    max-height: 10px;
    /* Ganti dengan tinggi yang Anda inginkan */
    overflow: hidden;
  }
}

.lonjong-image {
  width: 120px;
  /* Ganti dengan lebar yang Anda inginkan */
  height: 150px;
  /* Ganti dengan tinggi yang Anda inginkan */
  object-fit: cover;
  /* Menjaga proporsi gambar tetap proporsional */
}
</style>
