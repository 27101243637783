<template>
  <div class="fluid">
    <h4><i class="fa-solid fa-chart-bar mr-3 mt-4 mb-4"></i>DASHBOARD</h4>

    <div class="row">
      <div class="card bg-success ml-3 mb-2 text-white" style="width: 18rem">
        <div class="card-body">
          <div class="card-body-icon">
            <i class="fas fa-chalkboard-teacher mr-2"></i>
          </div>
          <h5 class="card-title">Data Asrama</h5>
          <div class="row ml-1">
            <div class="display-4">{{ dataasrama.length }}</div>
            <div class="display-4 ml-2 mr-2">:</div>

            <div class="display-4">{{ jmlasrama }}</div>
            <div class="display-5 mt-4 ml-2">Santri</div>
          </div>

          <!-- <a href=""
            ><p class="card-text text-white">
              Lihat Detail<i class="fas fa-angle-double-right ml-2"></i></p
          ></a> -->
        </div>
      </div>
      <div class="card bg-success ml-3 mb-2 text-white" style="width: 18rem">
        <div class="card-body">
          <div class="card-body-icon">
            <i class="fas fa-chalkboard-teacher mr-2"></i>
          </div>
          <h5 class="card-title">Data PMB</h5>
          <div class="row ml-1">
            <div class="display-4">{{ datapmb.length }}</div>
            <div class="display-4 ml-2 mr-2">:</div>

            <div class="display-4">{{ jmlpmb }}</div>
            <div class="display-5 mt-4 ml-2">Santri</div>
          </div>
          <!-- <a href=""
            ><p class="card-text text-white">
              Lihat Detail<i class="fas fa-angle-double-right ml-2"></i></p
          ></a> -->
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6" v-show="dataasrama.length > 0">
        <b-card>
          <template #header>
            <div class="row justify-content-between">
              <h4 class="mb-0">Data Peserta Asrama</h4>
              <b-overlay :show="loading" rounded="sm" spinner-small>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  title="Refresh"
                  @click="refresh()"
                  ><b-icon-arrow-repeat
                /></b-button>
              </b-overlay>
            </div>
          </template>
          <b-table
            :items="dataasrama"
            responsive
            sticky-header="500px"
            small
            hover
            style="font-size: 14px"
            @row-clicked="dataselect($event, 'asrama')"
            :tbody-tr-class="rowClass"
          />
        </b-card>
      </div>
      <div class="col-md-6" v-show="datapmb.length > 0">
        <b-card>
          <template #header>
            <div class="row justify-content-between">
              <h4 class="mb-0">Data Peserta PMB</h4>
              <b-overlay :show="loading" rounded="sm" spinner-small>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  title="Refresh"
                  @click="refresh()"
                  ><b-icon-arrow-repeat
                /></b-button>
              </b-overlay>
            </div>
          </template>
          <b-table
            :items="datapmb"
            responsive
            sticky-header="500px"
            small
            hover
            style="font-size: 14px"
            :tbody-tr-class="rowClass"
            @row-clicked="dataselect($event, 'pmb')"
          />
        </b-card>
      </div>
    </div>
    <div>
      <h3 class="mt-3">Detail Data {{ jdl }}</h3>
      {{ juduldetail }}
      <!-- {{ jmldetail }} -->
      <br />
      <div style="font-size: 13px">
        Tanggal:
        <a
          v-for="(detail, index) in jmldetail"
          :key="index"
          href="javascript:void(0)"
          @click="datadetail(detail.tanggal, detail.kode, detail)"
          :class="{ selected: selectedDetail === detail }"
        >
          {{ `T` + (index + 1) }}:{{ detail.tanggal }};
        </a>
      </div>
      <!-- <div class="row mt-4"> -->
      <b-table
        :items="detail"
        responsive
        small
        hover
        :style="{ fontSize: jdl === 'ASRAMA' ? '10px' : '12px' }"
      />
      <!-- :style="{ fontSize: status === 1 ? '14px' : '10px' }" -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      datapmb: [],
      dataasrama: [],
      detail: [],
      jmldetail: [],
      loading: false,
      jmlasrama: 0,
      jmlpmb: 0,
      juduldetail: "",
      selectedDetail: null,
      selectedrow: "",
      jdl: "",
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    datadetail(tgl, kode, item) {
      console.log("datadetail", tgl, kode);
      let nf = "";
      if (kode.substr(0, 3) == "PMB") {
        nf =
          "getdatatranpertgl.php?jenis=all&nopmb=" +
          kode +
          "&token=" +
          this.$globalData.Token;
      } else {
        nf =
          "detailpeserta.php?token=" +
          this.$globalData.Token +
          "&tanggal=" +
          tgl +
          "&kode=" +
          kode;
      }
      this.selectedDetail = item;
      axios.get(this.$appUrl + nf).then((res) => {
        // console.log("data jml distinct ", res.data);
        this.detail = res.data;
      });
    },
    dataselect(data, jenis) {
      console.log("data " + jenis, data, data.kode, jenis);
      this.selectedrow = data.kode;
      if (jenis == "asrama") {
        this.juduldetail = "Asrama: " + data.namaasrama;
        this.jdl = "ASRAMA";
      }
      if (jenis == "pmb") {
        this.juduldetail =
          "PMB: " + data.namapmb + " {TnR: Rating, TnH: Kehadiran}";
        this.jdl = "PMB";
      }
      this.detail = [];
      axios
        .get(
          this.$appUrl +
            "distinctpeserta.php?token=" +
            this.$globalData.Token +
            "&user=" +
            data.kode +
            "&jenis=" +
            jenis
        )
        .then((res) => {
          console.log("data jml distinct ", res.data);
          this.jmldetail = res.data;
        });
    },
    refresh() {
      this.loading = true;
      axios
        .get(
          this.$appUrl +
            "dashboard1.php?token=" +
            this.$globalData.Token +
            "&user=" +
            this.$globalData.UserId +
            "&level=" +
            this.$globalData.LevelLogin
        )
        .then((res) => {
          this.loading = false;
          console.log("data ", res.data);
          this.dataasrama = res.data.waliasrama;
          this.datapmb = res.data.pmb;
          this.jmlpmb = this.datapmb.reduce(
            (total, asrama) => total + parseInt(asrama.jmlsiswa),
            0
          );
          this.jmlasrama = this.dataasrama.reduce(
            (total, asrama) => total + parseInt(asrama.jmlsiswa),
            0
          );
        });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.kode === this.selectedrow) return "table-success";
    },
  },
};
</script>

<style>
.card-body-icon {
  position: absolute;
  z-index: 0;
  top: 20px;
  right: 20px;
  opacity: 0.3;
  font-size: 90px;
}
.selected {
  color: white;
  background-color: hsl(
    60,
    7%,
    56%
  ); /* Ubah warna latar belakang saat tautan dipilih */
}
</style>
