<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <!-- {{ selectedrow }} -->
        <b-card>
          <template #header>
            <h4 class="mb-0">Browse Data</h4>
          </template>
          <b-input-group size="sm">
            <template #append>
              <b-button variant="outline-success" @click="searchUsers(search)">
                <b-icon-search />
              </b-button>
            </template>
            <b-form-input placeholder="cari nama santri" v-model="search" @input="query = search"
              @keydown.enter="searchUsers(search)"></b-form-input>
          </b-input-group>
          <input type="file" style="display: none" ref="fileInput" @change="handleFileUpload" />
          <b-table small :items="data" :fields="['foto', 'nis']" hover @row-clicked="dataselected"
            :tbody-tr-class="rowClass" sticky-header="1000px" class="mt-2">
            <template #cell(pasfoto)="row">
              {{ row.item.pasfoto }}
            </template>
            <template #cell(foto)="row">
              <!-- :src="$appUrl + row.item.pasfoto" -->
              <b-img :src="getSrc(row.item.pasfoto)" width="60px" rounded @click="openFileInput" />
            </template>
            <template #cell(nis)="row">
              {{ row.item.nis }}<br />{{ row.item.nama }}
            </template>
          </b-table>
        </b-card>
      </div>
      <div class="col-md">
        <b-card header="Edit Profile Santri">
          <b-card>
            <p><b>Info Santri:</b></p>
            <b-table :items="infosantri" small style="font-size: 13px" hover outline :busy="isBusy" responsive>
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle" size="sm"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-card>
          <div class="col text-left mb-0" style="font-size: 12px; cursor: pointer;" @click="resetpassword">
            <i class="bi bi-key"></i>Reset Password
          </div>
          <div class="row mt-3">
            <div class="col-md-7">
              Nama Lengkap
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.nama, 'nama')" />
              <b-form-input size="sm" :value="form.nama | uppercase" v-model="form.nama"
                class="bold-input text-uppercase"></b-form-input>
            </div>
            <div class="col-md-5">
              Jenis Kelamin
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.jeniskel, 'jeniskel')" />
              <b-form-radio-group v-model="form.jeniskel" :options="[
                { text: 'Laki-laki', value: 'L' },
                { text: 'Perempuan', value: 'P' },
              ]"></b-form-radio-group>
            </div>
          </div>
          <div class="row mt-2 mb-2">
            <div class="col-md-7">
              Tempat Lahir
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.tempatlahir, 'tempatlahir')" />
              <b-form-input size="sm" v-model="form.tempatlahir" class="bold-input"></b-form-input>
            </div>
            <div class="col-md-5">
              Tanggal Lahir
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.tgllahir, 'tgllahir')" />
              <b-form-input size="sm" type="date" class="bold-input" v-model="form.tgllahir"></b-form-input>
            </div>
          </div>
          Alamat Lengkap
          <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
            @click="updatedata(form.nis, form.alamat, 'alamat')" />
          <b-textarea size="sm" v-model="form.alamat" class="bold-input"></b-textarea>
          <div class="row mt-2 mb-2">
            <div class="col-md-6">
              Kota
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.kota, 'kota')" />
              <b-form-input size="sm" v-model="form.kota" class="bold-input text-uppercase"></b-form-input>
            </div>
            <div class="col-md-2">
              Kode Pos
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.kodepos, 'kodepos')" />
              <b-form-input size="sm" v-model="form.kodepos" class="bold-input"></b-form-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-4">
              NIS
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.nis, 'nis')" />
              <b-form-input size="sm" v-model="form.nis" class="bold-input"></b-form-input>
            </div>
            <div class="col-md-4">
              NIK
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.nik, 'nik')" />
              <b-form-input size="sm" v-model="form.nik" class="bold-input"></b-form-input>
            </div>
            <div class="col-md-4">
              NISN<b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.nisn, 'nisn')" />
              <b-form-input size="sm" v-model="form.nisn" class="bold-input"></b-form-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3">
              Anak Ke
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.anake, 'anake')" />
              <b-form-input size="sm" type="number" v-model="form.anakke" class="bold-input"></b-form-input>
            </div>
            <div class="col-md-3">
              Dari
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.dari, 'dari')" />
              <b-form-input size="sm" type="number" v-model="form.dari" class="bold-input"></b-form-input>
            </div>
          </div>
          <div class="text-white bg-dark text-center mt-4 mb-2">
            Data Orang Tua (Ayah)
          </div>

          <div class="row">
            <div class="col-md-7">
              Nama Ayah
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.namaayah, 'namaayah')" />
              <b-form-input size="sm" v-model="form.namaayah"></b-form-input>
            </div>
            <div class="col-md-5">
              Status Ayah
              <b-form-radio-group :options="[
                { text: 'Ayah Kandung', value: '1' },
                { text: 'Wali', value: '2' },
              ]"></b-form-radio-group>
            </div>
          </div>
          <div class="row mt-2 mb-2">
            <div class="col-md-7">
              Tempat Lahir
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer" @click="
                updatedata(form.nis, form.tempatlahirayah, 'tempatlahirayah')
                " />
              <b-form-input size="sm" v-model="form.tempatlahirayah"></b-form-input>
            </div>
            <div class="col-md-5">
              Tanggal Lahir
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer" @click="
                updatedata(form.nis, form.tlgllahirayah, 'tgllahirayah')
                " />
              <b-form-input size="sm" type="date" v-model="form.tgllahirayah"></b-form-input>
            </div>
          </div>
          Alamat Lengkap
          <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
            @click="updatedata(form.nis, form.alamatortu, 'alamatortu')" />
          <b-textarea size="sm" v-model="form.alamatortu"></b-textarea>
          <div class="row mt-2 mb-2">
            <div class="col-md-6">
              Kota
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.kotaortu, 'kotaortu')" />
              <b-form-input size="sm" v-model="form.kotaortu"></b-form-input>
            </div>
            <div class="col-md-2">
              Kode Pos
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.kodeposortu, 'kodeposortu')" />
              <b-form-input size="sm" v-model="form.kodeposortu"></b-form-input>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-3">
              Pendidikan
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.pendidikanayah, 'pendidikanayah')" />

              <b-form-input size="sm" type="number"></b-form-input>
            </div>
            <div class="col-md-3">
              Pekerjaan
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.pekerjaanayah, 'pekerjaanayah')" />
              <b-form-input size="sm"></b-form-input>
            </div>
            <div class="col-md-3">
              Penghasilan
              <b-icon-arrow-repeat title="Update Field ini" style="cursor: pointer"
                @click="updatedata(form.nis, form.penghasilanayah, 'penghasilanayah')" />
              <b-form-input size="sm" type="number"></b-form-input>
            </div>
          </div>
          <div class="text-white bg-dark text-center mt-4 mb-2">
            Data Orang Tua (Ibu)
          </div>
          <div class="row">
            <div class="col-md-7">
              Nama Ibu
              <b-form-input size="sm"></b-form-input>
            </div>
            <div class="col-md-5">
              Status Ibu
              <b-form-radio-group :options="[
                { text: 'Ibu Kandung', value: '1' },
                { text: 'Wali', value: '2' },
              ]"></b-form-radio-group>
            </div>
          </div>
          <div class="row mt-2 mb-2">
            <div class="col-md-7">
              Tempat Lahir
              <b-form-input size="sm"></b-form-input>
            </div>
            <div class="col-md-5">
              Tanggal Lahir
              <b-form-input size="sm" type="date"></b-form-input>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-3">
              Pendidikan
              <b-form-input size="sm" type="number"></b-form-input>
            </div>
            <div class="col-md-3">
              Pekerjaan
              <b-form-input size="sm"></b-form-input>
            </div>
            <div class="col-md-3">
              Penghasilan
              <b-form-input size="sm" type="number"></b-form-input>
            </div>
          </div>
          <hr />
          <b-button size="sm" class="mt-0 mb-2 float-right">Update Data</b-button>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  name: "Datasiswa",
  components: {
    //  Navbar,
  },
  data() {
    return {
      data: [],
      infosantri: [],
      form: {
        nama: "",
        jeniskel: "",
        tgllahir: "",
        nohp: "",
        nisn: "",
        nik: "",
        alamat: "",
        kota: "",
        kodepos: "",
        alamatortu: "",
        kotaortu: "",
        kodeposortu: "",
        asalsekolah: "",
        jurusan: "",
        tinggibadan: "",
        beratbadan: "",
        anakke: "",
        dari: "",
        tempatlahir: "",
        namaayah: "",
        tempatlahirayah: "",
        pekerjaanayah: "",
        pendapatanayah: "",
        namaibu: "",
        tempatlahiribu: "",
        tgllahiribu: "",
        pekerjaanibu: "",
        pendapatanibu: "",
        nis: "",
        pasfoto: "",
        pendidikanayah: '',
      },
      password: "",
      ketlogin: "",
      query: "",
      search: "",
      selectedrow: "",
      profileImage: null,
      indexdata: 0,
      isBusy: false,
    };
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    query: _.debounce(function (newQuery) {
      this.searchUsers(newQuery);
    }, 300),
  },
  methods: {
    resetpassword() {
      this.$bvModal
        .msgBoxConfirm("Yakin Password untuk di Reset ?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .get(
                this.$appUrl +
                "resetpassword.php?nis=" +
                this.selectedrow +
                "&token=" +
                this.$globalData.Token
              )
              .then((res) => {
                console.log(res.data);
              });

          }
        })

    },
    getSrc(pasfoto) {
      // Jika 4 digit pertama dari pasfoto adalah 'blob', maka gunakan hanya nama pasfoto
      if (pasfoto && pasfoto.startsWith("blob")) {
        return pasfoto;
      } else {
        // Jika bukan, gabungkan dengan $appUrl
        return this.$appUrl + pasfoto;
      }
    },
    dataselected(item, index, event) {
      console.log("row select", item, index, event);
      this.indexdata = index;
      this.selectedrow = item.nis;
      this.getinfosantri(item.nis);
      let tg = new Date();
      this.form = item;
      this.profileImage =
        item.pasfoto == "" || item.pasfoto == null
          ? this.$appUrl + "foto/avatar.png"
          : this.$appUrl + item.pasfoto + "?d=" + tg;
    },
    getinfosantri(nis) {
      this.isBusy = true;
      axios
        .get(
          this.$appUrl +
          "getinfosantri.php?nis=" +
          nis +
          "&token=" +
          this.$globalData.Token
        )
        .then((res) => {
          this.infosantri = res.data;
          this.isBusy = false;
        });
    },
    openFileInput() {
      // Klik input file saat gambar diklik
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const uploadedFile = event.target.files[0];
      // Gunakan URL.createObjectURL() untuk menampilkan gambar yang dipilih oleh pengguna secara langsung
      // let tg = new Date();
      this.profileImage = URL.createObjectURL(uploadedFile);
      this.data[this.indexdata].pasfoto = this.profileImage;
      console.log(
        "Profile Image",
        this.data[this.indexdata].pasfoto,
        this.profileImage
      );
      this.uploadfile(uploadedFile);
    },
    uploadfile(nf) {
      // console.log("uploadfile", nf, th, index);
      //   this.valuedownload = 0;
      var config = {
        onUploadProgress: (progressEvent) => {
          // ini harus menggunakan arrow function
          console.log("progress event", progressEvent);
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.valuedownload = percentCompleted;
          console.log("progress", percentCompleted, this.valuedownload);
        },
        headers: {
          "Content-Type": "multipart/header-data",
        },
      };
      var data = new FormData();
      data.append("userid", this.selectedrow);
      data.append("file", nf);
      axios
        .post(
          this.$appUrl + "uploadfotosantri.php?token=" + this.$globalData.Token,
          data,
          config
        )
        .then((res) => {
          //  th.target.innerText = res.data.status;
          this.dok = null;
          this.$toast.success(res.data.status, {
            type: "success",
            position: "top-right",
            duration: 3000,
            dismissible: true,
          }); //   output.className = 'container';
          //   output.innerHTML = res.data;
        })
        .catch(function (err) {
          console.log("error", err);
          //   output.className = 'container text-danger';
          //   output.innerHTML = err.message;
        });
    },
    updatedata(nis, nama, field) {
      console.log("updatte", nis, nama, field);
      axios
        .get(
          this.$appUrl +
          "updatesantri.php?nis=" +
          nis +
          "&value=" +
          nama +
          "&field=" +
          field +
          "&token=" +
          this.$globalData.Token
        )
        .then((res) => {
          console.log(res);
          // this.data[this.indexdata][field] = nama;
        });
    },
    searchUsers(newQuery) {
      axios
        .get(
          this.$appUrl +
          `searchsiswa.php?q=${newQuery}` +
          "&token=" +
          this.$globalData.Token
        )
        .then((res) => {
          this.data = res.data;
        });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.nis === this.selectedrow) return "table-success";
    },
  },
  filters: {
    uppercase(value) {
      return value.toUpperCase();
    },
  },
};
</script>

<style>
.bold-input {
  font-weight: bold !important;
}
</style>
