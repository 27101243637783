<template>
  <div class="container-fluid">
    <b-card no-body>
      <template #header>
        <b-button block @click="visible = !visible" variant="success">
          <div class="row justify-content-between">
            <span class="ml-3">{{ judul }} </span> {{ datapmb.length }}Rows, #{{ jmlsiswa }}
            <b-overlay :show="loading" rounded="sm" spinner-small>
              <b-button size="sm" variant="success" title="Refresh" @click.stop.prevent="refresh(namatable)"
                class="mr-2"><b-icon-arrow-repeat /></b-button>
            </b-overlay>
          </div>
        </b-button>
      </template>

      <b-collapse :id="toggleId" visible :accordion="toggleId" role="tabpanel" v-model="visible">
        <div class="row p-2">
          <div class="col-md-6">
            <b-table :items="datapmb" :fields="['jml', 'nokelas', 'st', 'wali', 'act']" responsive sticky-header="500px"
              small hover style="font-size: 13px" :tbody-tr-class="rowClass" @row-clicked="dataselect"
              @row-dblclicked="dataselect2">
              <template #head(nokelas)>
                Data {{ $func.titleCase(namatable) }}
              </template>
              <template #cell(jml)="row">
                {{ row.item.jmlsantri }}
              </template>
              <template #cell(nokelas)="row">
                {{ row.item.namafield + " [" + row.item.kunci + "]" }}
              </template>
              <template #cell(st)="row">
                <b-icon-check-circle v-show="row.item.namawali != null" />
              </template>
              <template #cell(wali)="row">
                <b-form-select :options="listwali" size="sm" v-model="row.item.kodewali" :class="{
                  'bold-text': row.item.kodebuff != row.item.kodewali,
                }" />
              </template>
              <template #cell(act)="row">
                <div class="text-center">
                  <b>
                    <b-icon-pencil-square @click="update(row.item)" title="Update Data"
                      v-show="row.item.kodebuff != row.item.kodewali" style="cursor: pointer" /></b>
                </div>
              </template>
            </b-table>
          </div>
          <div class="col-md-6">
            List Anggota <b>{{ selectedrow }}</b> #{{ listanggota.length }}
            <b-link @click="
              satupeserta = true;
            cekdatapeserta = [];
            selectedUser = null;
            nis = '';
            showModal = true;
            " v-show="selectedrow">
              <b-icon-plus-square-dotted class="float-right" title="Tambah 1 Peserta" /></b-link>
            <b-link @click="
              satupeserta = false;
            formattedData = [];
            showModal = true;
            " v-show="selectedrow">
              <b-icon-plus-square class="float-right mr-3" title="Tambah Peserta" /></b-link>
            <b-link class="float-right mr-3" v-show="selectedrow" @click="deleteall" size="sm">
              <b-icon-trash />Delete All
            </b-link>

            <!-- <b-button v-b-modal="'modal-1'">Show Modal</b-button> -->
            <b-table :items="listanggota" :fields="['nis', 'nama', 'act']" responsive sticky-header="500px" small hover
              style="font-size: 13px" :busy="isBusy">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(act)="row">
                <b-icon-trash @click="deleteanggota(row)" />
              </template>
            </b-table>
          </div>
        </div>
      </b-collapse>
    </b-card>
    <b-modal id="modal-1" :title="'Add Peserta ' + selectedrow" no-close-on-backdrop v-model="showModal">
      <template #modal-footer>
        <div class="w-100">
          <p class="float-left">
            Peserta di {{ selectedrow }} tidak akan double
          </p>
          <b-button variant="primary" size="sm" class="float-right" @click="savepesertabaru"
            :disabled="cekdatapeserta.length > 0">
            <b-icon-save /> Save
          </b-button>
        </div>
      </template>
      <div v-show="satupeserta">
        <p class="my-2">Masukin NIS {{ nis }}</p>

        <vue-bootstrap-typeahead class="mb-4" size="sm" v-model="nis" :data="options"
          :serializer="(item) => item.nis + ' ' + item.nama" @hit="
            selectedUser = $event;
          cekpeserta();
          " @input="searchaccount" placeholder="cari nis atau nama" />
        {{ cekdatapeserta }}
      </div>
      <div v-show="!satupeserta">
        <b-link @click="getdatacapture"><b-icon-check-circle /> Cek Data</b-link>
        <span class="float-right">#{{ formattedData.length }}</span>
        <div class="row mt-2">
          <div class="col-md-4">
            <b-form-textarea v-model="textareaContent" rows="15" class="pr-4" />
            <b-icon-x v-if="textareaContent" @click="textareaContent = ''" class="position-absolute"
              style="top: 8px; right: 16px; z-index: 1" size="sm" />
            <!-- &times;
            </b-icon-x> -->
          </div>
          <div class="col-md-8">
            <b-table :items="formattedData" style="font-size: 12px" small sticky-header="400px" show-empty
              :busy="loadingcek">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #empty>
                <h4>No Data</h4>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  name: "Showdata2",
  props: ["namatable", "judul"],
  components: {},
  data() {
    return {
      datapmb: [],
      listwali: [],
      loading: false,
      visible: false,
      selectedrow: "",
      selectedrow2: "",
      listanggota: [],
      options: [],
      cekdatapeserta: [],
      isBusy: false,
      showModal: false,
      nis: "",
      selectedUser: null,
      satupeserta: true,
      textareaContent: "",
      fields: [],
      formattedData: [],
      loadingcek: false,
      jmlsiswa: 0,
    };
  },
  computed: {
    toggleId() {
      return `toggle-${this.namatable}`;
    },
  },
  mounted() {
    this.refresh(this.namatable);
  },
  methods: {
    deleteall() {
      this.$bvModal
        .msgBoxConfirm("Yakin untuk di DELETE ALL ? ", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .get(
                this.$appUrl +
                "delpeserta.php?kode=" +
                this.selectedrow +
                "&jenis=" +
                this.namatable +
                "&token=" +
                this.$globalData.Token
              )
              .then(() => {
                this.listanggota = [];
                this.dataselect(this.selectedrow2);
                this.refresh(this.namatable);
                //  this.$bvModal.msgBoxOk(
                //    "Cek lagi data pesertanya, apakah sudah terhapus"
                //  );
              });
          }
        });
    },
    getdatacapture() {
      const rows = this.textareaContent.split("\n");
      let dt = "";
      rows.forEach((element) => {
        dt = dt + "'" + element + "',";
      });
      console.log("data", dt);
      // this.formattedData = rows;
      this.loadingcek = true;
      axios
        .post(
          this.$appUrl + "ceknismore.php?token=" + this.$globalData.Token,
          dt
        )
        .then((res) => {
          this.formattedData = res.data;
          this.loadingcek = false;
          this.cekdatapeserta = [];
        });
    },
    cekpeserta() {
      if (this.namatable == "pmb") {
        return;
      }
      axios
        .get(
          this.$appUrl +
          "cekpeserta.php?jenis=" +
          this.namatable +
          "&nis=" +
          this.selectedUser.nis +
          "&token=" +
          this.$globalData.Token
        )
        .then((res) => {
          this.cekdatapeserta = res.data;
        });
    },
    savepesertabaru() {
      // this.$bvModal.msgBoxOk(JSON.stringify(this.selectedUser));
      if (!this.satupeserta) {
        if (this.formattedData.length == 0) {
          this.$bvModal.msgBoxOk("Cek Data terlebih dahulu, data masih kosong");
          return;
        }
      } else {
        if (!this.selectedUser) {
          this.$bvModal.msgBoxOk("Nis Masih Kosong");
          return;
        }
      }
      this.$bvModal
        .msgBoxConfirm(
          (this.satupeserta ? this.selectedUser.nama : "") +
          " Yakin untuk di ADD ? ",
          {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okTitle: "YES",
            cancelTitle: "NO",
            noCloseOnBackdrop: true,
          }
        )
        .then((value) => {
          if (value) {
            console.log("save");
            let nis = this.satupeserta ? this.selectedUser.nis : "";
            axios
              .post(
                this.$appUrl +
                "savetambahpeserta.php?kode=" +
                this.selectedrow +
                "&jenis=" +
                this.namatable +
                "&nis=" +
                nis +
                "&satudata=" +
                this.satupeserta +
                "&token=" +
                this.$globalData.Token,
                this.formattedData
              )
              .then(() => {
                this.dataselect(this.selectedrow2);
                this.refresh(this.namatable);
              });
          }
        });

      this.showModal = false;
    },
    searchaccount: _.debounce(function (newQuery) {
      axios
        .get(
          this.$appUrl +
          `searchnis.php?q=${newQuery}` +
          "&token=" +
          this.$globalData.Token
        )
        .then((res) => {
          this.options = res.data;
          console.log("Search NIS", this.options);
        });
    }, 300), // 300 milidetik debounce

    deleteanggota(row) {
      // console.log("delete anggota", this.selectedrow, item);
      this.$bvModal
        .msgBoxConfirm(row.item.nis + " Yakin untuk di DELETE ? ", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .get(
                this.$appUrl +
                "deletepeserta.php?jenis=" +
                this.namatable +
                "&nis=" +
                row.item.nis +
                "&kode=" +
                this.selectedrow +
                "&token=" +
                this.$globalData.Token
              )
              .then((res) => {
                console.log("delete", res.data);
                this.listanggota.splice(row.index, 1);
                this.refresh(this.namatable);
              });
          }
        });
    },
    rowClass(item, type) {
      // if (!item || type !== "row") return;
      // console.log("rowclas", item.kode, this.selectedrow);
      if (item.kode === this.selectedrow) {
        console.log("OK", type);
      }
      if (item.kunci === this.selectedrow) return "table-success";
    },
    dataselect2(item) {
      this.$bvModal.msgBoxOk(JSON.stringify(item));
    },
    dataselect(item) {
      this.isBusy = true;
      this.selectedrow = item.kunci;
      this.selectedrow2 = item;

      console.log("item update", this.namatable, item);
      axios
        .get(
          this.$appUrl +
          "showdataupload.php?jenis=" +
          this.namatable +
          "&kode=" +
          item.kunci +
          "&token=" +
          this.$globalData.Token
        )
        .then((res) => {
          this.listanggota = res.data;
          this.isBusy = false;
        });
    },
    update(item) {
      console.log("item update", this.namatable, item);
      axios.post(
        this.$appUrl +
        "saveasrama.php?jenis=" +
        this.namatable +
        "&noas=" +
        item.kunci +
        "&wali=" +
        item.kodewali +
        "&asrama=" +
        item.code +
        "&token=" +
        this.$globalData.Token,
        item
      );
      item.namawali = "update";
      item.kodebuff = item.kodewali;
    },
    refresh(para) {
      this.loading = true;
      axios
        .get(
          this.$appUrl +
          "loadmasterasrama.php?token=" +
          this.$globalData.Token +
          "&namafile=" +
          para
        )
        .then((res) => {
          this.loading = false;
          this.datapmb = res.data.asrama;
          const totalNilai = this.datapmb.reduce((total, item) => total + parseFloat(item.jmlsantri), 0);
          this.jmlsiswa = totalNilai
          this.listwali = res.data.wali;
        });
    },
  },
};
</script>

<style>
.bold-text {
  font-weight: bold !important;
}
</style>
