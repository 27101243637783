<template>
  <div class="container">
    <!-- <div @click="refresh('2')">NOTES:</div> -->
    <b-link @click="refresh('1')" :class="{ 'mr-2': true, 'bg-green': active === '1' }">Catan BEER</b-link>
    <b-link @click="refresh('2')" :class="{ 'mr-2': true, 'bg-green': active === '2' }">Database Restore</b-link>
    <b-link @click="refresh('3')" :class="{ 'mr-2': true, 'bg-green': active === '3' }">MyNote</b-link>

    <!-- <b-form-textarea
      v-model="notes"
      size="sm"
      rows="25"
      class="mb-3"
    ></b-form-textarea> -->
    <div class="custom-editor">
      <vue-editor v-model="notes" id="editor1" class="mb-3"></vue-editor>
    </div>
    <!-- <div v-html="notes"></div> -->
    <!-- <b-button size="sm" @click="refresh('1')" class="mr-2">Refresh</b-button> -->
    <b-button size="sm" @click="updatenotes(active)">Update</b-button>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  name: "MyNotes",
  components: {
    VueEditor,
  },
  data() {
    return {
      notes: "",
      active: '1',
    };
  },
  mounted() {
    this.refresh("1");
  },
  methods: {
    refresh(para) {
      this.active = para
      axios.get(this.$appUrl + "getnotes.php?iden=" + para)
        .then((response) => {
          this.notes = response.data[0].notes;
        });
    },
    updatenotes(para) {
      axios.post(this.$appUrl + "updatenotes.php?para=" + para, { data: this.notes });
    },
  },
};
</script>

<style>
#editor1 {
  height: 450px;
}

.bg-green {
  background-color: green;
  color: white;
  /* Optional: to ensure the text is readable */
}
</style>
