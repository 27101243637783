<template>
  <div class="container-fluid">
    <b-card>
      <template #header>
        <div class="row justify-content-between">
          <h5 class="mb-0">{{ judul }}</h5>
          #{{ datapmb.length }}
          <b-overlay :show="loading" rounded="sm" spinner-small>
            <b-button
              size="sm"
              variant="outline-primary"
              title="Refresh"
              @click="refresh(namatable)"
              ><b-icon-arrow-repeat
            /></b-button>
          </b-overlay>
        </div>
      </template>
      <div class="row">
        <div class="col-6">
          <b-form-input
            size="sm"
            class="mb-1"
            v-model="searchfilter"
            placeholder="search text"
            type="search"
          />
        </div>
        <div class="col-6"></div>
      </div>
      <b-form-tags
        id="tags-component-select"
        v-model="field"
        size="lg"
        class="mb-2"
        add-on-change
        no-outer-focus
      >
        <template
          v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }"
        >
          <ul
            v-if="tags.length > 0"
            class="list-inline d-inline-block mb-2"
            style="font-size: 14px"
          >
            <li v-for="tag in tags" :key="tag" class="list-inline-item">
              <b-form-tag
                @remove="removeTag(tag)"
                :title="tag"
                :disabled="disabled"
                variant="info"
                >{{ tag }}</b-form-tag
              >
            </li>
            <b-link class="mt-3" @click="field = []"><b-icon-trash /></b-link>
          </ul>
          <b-form-select
            v-bind="inputAttrs"
            v-on="inputHandlers"
            :disabled="disabled || availableOptions.length === 0"
            :options="availableOptions"
          >
            <template #first>
              <!-- This is required to prevent bugs with Safari -->
              <option disabled value="">Pilih field ...</option>
            </template>
          </b-form-select>
        </template>
      </b-form-tags>
      <b-table
        :items="datapmb"
        :fields="field"
        responsive
        sticky-header="500px"
        small
        hover
        style="font-size: 13px"
        show-empty
        @row-clicked="showprofiles"
        :filter="searchfilter"
        :tbody-tr-class="rowClass"
      >
        <template #empty>
          <h5>No Data</h5>
        </template>
        <template #cell(pasfoto)="row">
          <b-img
            :src="
              'https://apps.baiturrahman.com/php.script/' + row.item.pasfoto
            "
            width="100px"
          />
        </template>
      </b-table>

      <!-- {{ fields_sort }}, {{ fields }} -->
    </b-card>
    <Profile2
      message="modalMessage"
      v-model="showmodal"
      :form="deepCopy"
      :listhak="listhak"
    />
  </div>
</template>

<script>
import axios from "axios";
import Profile2 from "@/components/profile2.vue";

export default {
  name: "Showdata",
  props: ["namatable", "judul"],
  components: { Profile2 },
  data() {
    return {
      datapmb: [],
      loading: false,
      showmodal: false,
      deepCopy: {},
      searchfilter: "",
      selectedrow: "",
      listhak: [],
      field: [],
      selected: [],
      fields_sort: [],
    };
  },
  computed: {
    availableOptions() {
      return this.fields.filter((opt) => this.field.indexOf(opt) === -1);
    },
  },

  watch: {},
  mounted() {
    if (this.namatable == "siswa") {
      // this.field = ["pasfoto", "nis", "nama", "tgllahir"];
    }
  },

  methods: {
    onTagState() {},
    changeselect() {
      if (this.selected && !this.field.includes(this.selected)) {
        this.field.push(this.selected);
      }
    },

    rowClass(item, type) {
      // console.log("item", item.nodisiplin, this.selectedrow);
      if (!item || type !== "row") return;
      if (item.namauser === this.selectedrow) return "table-success";
    },
    showprofiles(item) {
      if (this.namatable == "userid") {
        this.selectedrow = item.namauser;
        console.log("Masuk Show Profile", item);
        // this.deepCopy = JSON.parse(JSON.stringify(this.$globalData.datauser));
        this.deepCopy = item;
        this.showmodal = true;
        axios
          .get(
            this.$appUrl +
              "listhakuser.php?token=" +
              this.$globalData.Token +
              "&user=" +
              item.namauser
          )
          .then((res) => {
            this.listhak = res.data;
          });
      }
    },
    updateSelected() {
      let nm = [];
      nm = this.fields
        .filter((option) => option.field)
        .map((option) => option.value);
      console.log("items", nm);
    },
    refresh(para) {
      this.loading = true;
      axios
        .get(
          this.$appUrl +
            "showdata.php?token=" +
            this.$globalData.Token +
            "&para=" +
            para
        )
        .then((res) => {
          this.loading = false;
          this.datapmb = res.data;
          if (this.datapmb.length > 0) {
            this.fields = Object.keys(this.datapmb[0]);
            if (this.namatable == "siswa") {
              this.field = ["pasfoto", "nis", "nama", "tgllahir","password"];
            } else {
              this.field = this.fields;
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.tag-container {
  border: none; /* Hapus border bawaan */
  box-shadow: none; /* Hapus shadow bawaan */
}

.tag-container .btn {
  display: none; /* Sembunyikan tombol tambah */
}
</style>
