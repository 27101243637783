<template>
  <div class="container-fluid">
    <b-card header="Penilaian Ibadah Santri" :no-body="isMobile2">
      <div class="row">
        <div class="col-md-4 mb-2">
          <b-card>
            <b-form-select v-model="walas" :options="listwalas" size="sm" @input="getsantri()" />
            <div class="text-center mt-2 mb-2">
              Wali Asrama: <b>{{ namawali }}</b> <br />
              Tahun:
              {{ tahun }}
            </div>

            <b-table style="font-size: 13px" :items="listsantri" :fields="['nis', 'nama']" size="sm" bordered hover
              outline responsive @row-clicked="dataselect" small>
              <template #cell(nis)="row">
                {{ row.item.nis }}
              </template>
            </b-table>
          </b-card>
        </div>
        <div class="col-md-8">
          <!-- NIS:{{ nis }}, Noasrama {{ noasrama }}, Santri: {{ namasantri }} -->
          <b-card>
            <template #header>
              <div>
                <h4 class="mb-0 text-center">{{ namasantri }}</h4>
              </div>
            </template>
            <div class="row">
              <div class="col-md-8">
                <b-calendar class="border rounded p-2" locale="en" v-model="tanggal" hide-header today-variant="warning"
                  block label-help="" @input="gettemplate" :disabled="namasantri == ''"></b-calendar>
              </div>
              <div class="col-md-4 d-flex align-items-end">
                <span class="text-left" style="font-size: 12px">
                  <b>Skala Qiyamulail</b>: <br />5(03.00-03.15, 4(03.15-03.30),
                  <br />
                  3(03.30-03.45), 2(03.45-04.00),<br />1(04.00-subuh)<br />
                  <b>Skala Kehadiran</b>:<br />1(Hadir),2(Izin Asrama),3(Sakit),
                  4(Alpa)</span>
              </div>
            </div>
            <div class="mt-2 mb-2">
              Tanggal Aktif: <b>{{ tanggal }}</b> an. <b>{{ namasantri }}</b>
            </div>
            <b-button size="sm" class="float-right" v-show="namasantri != ''" @click="deletefield"><b-icon-trash />
              Delete
            </b-button>
            <!-- filter="1"
              filter-included-fields="aktif" -->
            <b-table :items="listpenilaianfilter" :fields="['waktu', 'deskripsi', 'status']" responsive :busy="isbusy"
              hover outline style="font-size: 14px" small>
              <template #head(status)="data">
                <div style="cursor: pointer" @click="clickstatus" :disabled="namasantri == ''">
                  {{ data.label }}
                </div>
              </template>

              <template #cell(waktu)="row">
                {{ row.item.header != "1" ? row.item.waktu : "" }}
              </template>

              <template #cell(status)="row">
                <div v-show="namasantri != ''">
                  <div v-show="row.item.ispoint == '1' && row.item.header != '1'">
                    <b-form-radio-group v-model="listpenilaianfilter[row.index][tgl]" :options="row.item.kodepenilaian == '2425.01'
                      ? ['1', '2', '3', '4', '5']
                      : ['1', '2', '3', '4', '5']
                      " @change="
                        updatefield($event, row.item.kodepenilaian, 'one')" size="sm"></b-form-radio-group>
                  </div>
                  <div v-show="row.item.ispoint == '0' && row.item.header != '1'">
                    <b-form-checkbox size="sm" value="1" unchecked-value="0" @change="
                      updatefield($event, row.item.kodepenilaian, 'one')
                      " v-model="listpenilaianfilter[row.index][tgl]"></b-form-checkbox>
                  </div>
                </div>
              </template>
            </b-table>
            <b-table v-show="false" :items="listpenilaianfilter" responsive style="font-size: 10px" :fields="[
              'deskripsi',
              'row',
              'tg01',
              'tg02',
              'tg03',
              'tg04',
              'tg05',
              'tg06',
              'tg07',
              'tg08',
              'tg09',
              'tg10',
              'tg11',
              'tg12',
              'tg13',
              'tg14',
              'tg15',
              'tg16',
              'tg17',
              'tg18',
              'tg19',
              'tg20',
              'tg21',
              'tg22',
              'tg23',
              'tg24',
              'tg25',
              'tg26',
              'tg27',
              'tg28',
              'tg29',
              'tg30',
              'tg31',
            ]">
              <template #cell(row)="row">
                {{ row.index }}
              </template>
            </b-table>
            <!-- v-model="tanggal" -->
            <!-- todayButton="true" -->
          </b-card>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
// import Upload from "@/components/upload.vue";
// import VueCal from "vue-cal";
// import "vue-cal/dist/vuecal.css";

export default {
  name: "Penilaian",
  //   components: { VueCal },
  data() {
    return {
      isMobile2: false,
      walas: "",
      tanggal: "",
      namasantri: "",
      namawali: "",
      tahun: "",
      listwalas: [],
      listsantri: [],
      listpenilaian: [],
      listpenilaianfilter: [],
      tahun2: "",
      bulan: "",
      noasrama: "",
      nis: "",
      tgl: "01",
      kodepenilaian: "",
      isbusy: false,
    };
  },
  mounted: async function () {
    this.tanggal = this.$func.tglhariini();
    //  this.form.waktu = "10:00";
    await this.getwalas();
    this.checkDevice();
  },
  methods: {
    checkDevice() {
      // Gunakan lebar layar untuk memutuskan apakah perangkat mobile atau tidak
      this.isMobile2 = window.innerWidth <= 768; // Ubah nilai 768 sesuai kebutuhan Anda
      console.log("mobile device", this.isMobile2);
    },
    updatefield(val, kode, kd) {
      this.isbusy = true;
      // alert("save :" + val);
      let tahun = this.tanggal.substr(2, 2);
      let bulan = this.tanggal.substr(5, 2);
      let periode = tahun + "/" + bulan;
      axios
        .get(
          this.$appUrl +
          "updatefield.php?token=" +
          this.$globalData.Token +
          "&userid=" +
          this.$globalData.UserId +
          "&nis=" +
          this.nis +
          "&noasrama=" +
          this.noasrama +
          "&kodepenilaian=" +
          kode +
          "&periode=" +
          periode +
          "&field=" +
          this.tgl +
          "&jenis=" +
          kd +
          "&nilai=" +
          val
        )
        .then((res) => {
          console.log("Data ", res.data);
          this.isbusy = false;
        });
    },
    deletefield() {
      let tahun = this.tanggal.substr(2, 2);
      let bulan = this.tanggal.substr(5, 2);
      let periode = tahun + "/" + bulan;
      axios
        .get(
          this.$appUrl +
          "deletefield.php?token=" +
          this.$globalData.Token +
          "&userid=" +
          this.$globalData.UserId +
          "&nis=" +
          this.nis +
          "&noasrama=" +
          this.noasrama +
          "&periode=" +
          periode +
          "&field=" +
          this.tgl +
          "&jenis=all" +
          "&nilai=0"
        )
        .then((res) => {
          console.log("Data ", res.data);
          this.isbusy = false;
          this.gettemplate();
        });
    },
    clickstatus() {
      //   alert("status clicked");
      for (let index = 0; index < this.listpenilaianfilter.length; index++) {
        // const element = array[index];
        this.listpenilaianfilter[index][this.tgl] =
          this.listpenilaianfilter[index][this.tgl] == "1" ? 0 : 1;
      }
      this.updatefield("1", "2", "all");
    },
    gettemplate() {
      // alert("get template");
      this.tahun2 = this.tanggal.substr(0, 4);
      this.bulan = this.tanggal.substr(5, 2);
      this.tgl = "tg" + this.tanggal.substr(-2);
      this.isbusy = true;
      axios
        .get(
          this.$appUrl +
          "gettemplate.php?token=" +
          this.$globalData.Token +
          "&userid=" +
          this.$globalData.UserId +
          "&nis=" +
          this.nis +
          "&noasrama=" +
          this.noasrama +
          "&tanggal=" +
          this.tanggal +
          "&tahun=" +
          this.tahun2 +
          "&bulan=" +
          this.bulan
        )
        .then((res) => {
          console.log("Data ", res.data);
          this.isbusy = false;

          this.listpenilaian = res.data;
          //  var dt = data.filter(function (item) {
          //    return item.kelas === "01";
          //  });
          this.listpenilaianfilter = this.listpenilaian.filter(function (item) {
            return item.aktif == "1";
          });
        });
    },
    dataselect(item) {
      this.namasantri = item.nama;
      this.nis = item.nis;
      this.gettemplate();
    },
    getsantri() {
      axios
        .get(
          this.$appUrl +
          "searchwalas.php?token=" +
          this.$globalData.Token +
          "&user=" +
          this.$globalData.UserId +
          "&q=" +
          this.walas
        )
        .then((res) => {
          // if (res.data.length != 0) {
          //   this.details = res.data;
          // }
          console.log("Data ", res.data);
          this.listsantri = res.data;
          if (this.listsantri) {
            this.noasrama = this.listsantri[0].noasrama;
            this.nis = this.listsantri[0].nis;
            this.namawali = this.listsantri[0].namawali;
            this.kelas = this.listsantri[0].kelas;
          }
        });
    },
    async getwalas() {
      axios
        .get(
          this.$appUrl +
          "listwalas.php?token=" +
          this.$globalData.Token +
          "&user=" +
          this.$globalData.UserId +
          "&level=" +
          this.$globalData.LevelLogin
        )
        .then((res) => {
          // if (res.data.length != 0) {
          //   this.details = res.data;
          // }
          console.log("Data ", res.data);
          this.listwalas = res.data;
        });
    },
  },
};
</script>

<style scoped>
.gradient {
  background: linear-gradient(to bottom, #134a6e 33%, #66ff99 100%);
}

.pointer {
  cursor: pointer;
}
</style>
