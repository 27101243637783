<template>
  <div class="container-fluid">
    <h4 class="mr-3 mt-4 mb-4">
      <i class="bi bi-speedometer2"></i>DASHBOARD ADMIN
    </h4>

    <div class="row">
      <div class="col-md-6">
        <b-card>
          <template #header>
            <div class="row justify-content-between">
              <h4 class="mb-0">
                Data Santri Ajaran {{ $globalData.CurrentYear }}
              </h4>
              <b-overlay :show="loading" rounded="sm" spinner-small>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  title="Refresh"
                  @click="refresh('3')"
                  ><b-icon-arrow-repeat
                /></b-button>
              </b-overlay>
            </div>
          </template>
          <b-table
            :items="data3"
            responsive
            sticky-header="500px"
            small
            hover
            style="font-size: 14px"
          />
        </b-card>
      </div>
      <div class="col-md-6">
        <b-card>
          <template #header>
            <div class="row justify-content-between">
              <h4 class="mb-0">Data Santri Detail</h4>
              <b-overlay :show="loading" rounded="sm" spinner-small>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  title="Refresh"
                  @click="refresh('4')"
                  ><b-icon-arrow-repeat
                /></b-button>
              </b-overlay>
            </div>
          </template>
          <b-table
            :items="data4"
            responsive
            sticky-header="500px"
            small
            hover
            style="font-size: 14px"
          />
        </b-card>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6">
        <b-card>
          <template #header>
            <div class="row justify-content-between">
              <h4 class="mb-0">Data Santri di Asrama Ikhwan</h4>
              <span class="mt-2">#{{ jmlsantril }}</span>
              <b-overlay :show="loading" rounded="sm" spinner-small>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  title="Refresh"
                  @click="refresh('2')"
                  ><b-icon-arrow-repeat
                /></b-button>
              </b-overlay>
            </div>
          </template>
          <!-- {{ data2a }} -->

          <b-table
            :items="data2a"
            :fields="['namawali', 'kelas', 'namaasrama', 'jumlah']"
            responsive
            sticky-header="500px"
            small
            hover
            style="font-size: 14px"
          />
        </b-card>
      </div>
      <div class="col-md-6">
        <b-card>
          <template #header>
            <div class="row justify-content-between">
              <h4 class="mb-0">Data Santri di Asrama Akhwat</h4>
              <span class="mt-2">#{{ jmlsantrip }}</span>
              <b-overlay :show="loading" rounded="sm" spinner-small>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  title="Refresh"
                  @click="refresh('2')"
                  ><b-icon-arrow-repeat
                /></b-button>
              </b-overlay>
            </div>
          </template>
          <!-- {{ data2b }} -->
          <b-table
            :items="data2b"
            :fields="['namawali', 'kelas', 'namaasrama', 'jumlah']"
            responsive
            sticky-header="500px"
            small
            hover
            style="font-size: 14px"
          />
        </b-card>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6">
        <b-card>
          <template #header>
            <div class="row justify-content-between">
              <h4 class="mb-0">Cek Santri ABSEN dalam Kelas/PMB/Asrama</h4>
              <b-overlay :show="loading" rounded="sm" spinner-small>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  title="Refresh"
                  @click="refresh('1')"
                  ><b-icon-arrow-repeat
                /></b-button>
              </b-overlay>
            </div>
          </template>
          <b-table
            :items="data1"
            responsive
            sticky-header="500px"
            small
            hover
            style="font-size: 14px"
          />
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Dashboardadmin",
  components: {},
  data() {
    return {
      data1: [],
      data2a: [],
      data2b: [],
      data3: [],
      data4: [],
      loading: false,
      jmlsantril: 0,
      jmlsantrip: 0,
    };
  },
  mounted() {
    this.refresh("1");
    this.refresh("2");
    this.refresh("3");
  },
  methods: {
    refresh(para) {
      this.loading = true;
      axios
        .get(
          this.$appUrl +
            "dashboardadmin.php?token=" +
            this.$globalData.Token +
            "&tahun=" +
            this.$globalData.CurrentYear +
            "&jenis=" +
            para
        )
        .then((res) => {
          this.loading = false;
          if (para == "1") {
            this.data1 = res.data;
          }

          if (para == "2") {
            let vardata = res.data;
            this.jmlsantril = 0;
            this.jmlsantrip = 0;
            for (var i = 0; i < vardata.length; i++) {
              if (vardata[i].namaasrama != null) {
                var namaasramaSplit = vardata[i].namaasrama.split("|");
                vardata[i].jenisasrama = namaasramaSplit[0].trim();
                vardata[i].namawali = namaasramaSplit[1].trim();
                vardata[i].namaasrama = namaasramaSplit[2].trim();
              } else {
                vardata[i].namawali = "";
                vardata[i].namaasrama = "";
              }
              if (vardata[i].jenisasrama == "L") {
                this.jmlsantril += parseFloat(vardata[i].jumlah);
              } else {
                this.jmlsantrip += parseFloat(vardata[i].jumlah);
              }
            }
            //  let datL = data.filter(item => item.namaasrama.charAt(0) === 'L');
            //  let datP = data.filter(item => item.namaasrama.charAt(0) === 'P');
            this.data2a = vardata.filter((item) => item.jenisasrama == "L");
            this.data2b = vardata.filter((item) => item.jenisasrama == "P");
          }
          if (para == "3") {
            let vardata2 = res.data;
            let jl = 0;
            let jp = 0;
            for (var ii = 0; ii < vardata2.length; ii++) {
              vardata2[ii].jumlah =
                parseFloat(vardata2[ii].laki) +
                parseFloat(vardata2[ii].perempuan);
              jp += parseFloat(vardata2[ii].perempuan);
              jl += parseFloat(vardata2[ii].laki);
            }
            vardata2.push({ laki: jl, perempuan: jp, jumlah: jp + jl });
            console.log("jumlah", jl, jp);
            this.data3 = vardata2;
          }
          if (para == "4") {
            let vardata4 = res.data;
            let newdata = [];
            let jl = 0;
            let jp = 0;
            let jn = "";
            let jla = 0;
            let jpa = 0;
            // for (var n = 0; n < vardata4.length; n++) {
            let n = 0;
            while (n < vardata4.length) {
              jl = 0;
              jp = 0;
              jn = vardata4[n].jenjang;
              while (jn === vardata4[n].jenjang) {
                jp += parseFloat(vardata4[n].perempuan);
                jl += parseFloat(vardata4[n].laki);
                jpa += parseFloat(vardata4[n].perempuan);
                jla += parseFloat(vardata4[n].laki);
                newdata.push({
                  jenjang: vardata4[n].jenjang,
                  jenjangkelas: vardata4[n].jenjangkelas,
                  laki: vardata4[n].laki,
                  perempuan: vardata4[n].perempuan,
                });
                n += 1;
                if (n == vardata4.length) {
                  break;
                }
              }
              newdata.push({
                jenjangkelas: "Tot " + jn,
                laki: jl,
                perempuan: jp,
              });
            }
            newdata.push({ jenjangkelas: "TOTAL", laki: jla, perempuan: jpa });
            newdata.push({ jenjangkelas: "TOTAL ALL", perempuan: jpa + jla });

            this.data4 = newdata;
          }
        });
    },
  },
};
</script>

<style></style>
