<template>
  <div class="fluid-container text-center px-1">
    <b-card class="col-md-12 px-0 mt-4 mb-4 text-center shadow" no-body
      :header="'Administrasi Perizinan ' + $globalData.CurrentYear">
      <div class="row mt-2 mb-2 text-left">
        <div class="col-2 ml-4">Periode</div>
        <div class="col-2">
          <b-form-select :options="['Today', 'Week', 'Month', 'Periode']" size="sm" class="ml-2" v-model="opt"
            @change="refresh" />
        </div>
        <div class="col" v-show="opt == 'Periode'">
          <b-input-group prepend="Dari Tgl sd." size="sm">
            <b-form-input type="date" v-model="tg1" class="col-3" />
            <b-form-input type="date" v-model="tg2" class="col-3" />
            <!-- <b-input-group-append>
              <b-button variant="outline-success" @click="goproses('pmb')">Go Proses</b-button>
            </b-input-group-append> -->
          </b-input-group>
        </div>
      </div>
      <div class="row mb-2 text-left">
        <div class="col-2 ml-4">Search Name</div>
        <div class="col-2">
          <b-form-input size="sm" class="ml-2" v-model="search" @change="refresh" />
        </div>
        <div class="col">
          <b-button variant="outline-success" @click="refresh" size="sm">OK</b-button> <span class="mt-3"
            style="font-style: italic; font-size: 13px;">Tgl menggunakan
            tglpengajuan (#{{ info.length }})</span>
        </div>
      </div>

      <!-- {{ opt }} -->
      <div class="p-2">
        <b-table :items="info" hover responsive :busy="isBusy" style="font-size: 13px" class="text-left w-100"
          @row-clicked="dataselected">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" size="sm"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </b-card>
    <br />
    <br />
    <br />
    <b-modal v-model="modal" :title="'Approve an. ' + rowizin.nama + '(' + rowizin.noizin + ')'" ok-only>
      <template #modal-footer>
        Approved: {{ rowizin.tglapprove }}({{ rowizin.approvedby }})
        <b-button size="sm" :disabled="rowizin.noizin != ''" @click="izinok(rowizin.kodeunix)"><i
            class="bi bi-bookmark-check"></i> SETUJU
        </b-button>
      </template>
      <div class="row">
        <div class="col">
          <qr-code :text="rowizin.noizin" :size="200" />
        </div>
        <div class="col" style="font-size: 13px">
          dari Tgl:<br />
          {{ rowizin.mulai }} {{ rowizin.jamkeluar }}<br />
          SD.<br />
          {{ rowizin.sampai }} {{ rowizin.jamdatang }}<br />
          Alasan:<br />
          {{ rowizin.alasanizin }}<br />
          Scan Keluar:<br />
          {{ rowizin.scankeluar }}<br />
          Scan Masuk:<br />
          {{ rowizin.scanmasuk }} <br />
          Approved:<br />
          {{ rowizin.tglapprove }} {{ rowizin.approvedby }}
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AdminIzin",
  components: {
    //  Navbar,
  },
  data() {
    return {
      password: "",
      tgllahir: "",
      ketlogin: "",
      urladdress: "https://apps.baiturrahman.com/php.script/",
      opt: "Today",
      search: "",
      info: [],
      isBusy: false,
      modal: false,
      rowizin: {},
      tg1: '',
      tg2: '',
    };
  },
  mounted() {
    this.refresh();
    this.tg1 = this.$func.tglhariini();
    this.tg2 = this.$func.tglhariini();

  },
  methods: {
    izinok(kode) {
      // alert(kode);
      axios
        .get(
          this.$appUrl +
          "approveizin.php?kode=" +
          kode +
          "&user=" +
          this.$globalData.UserId
        )
        .then((res) => {
          this.rowizin.noizin = res.data[0]["noizin"];
          this.rowizin.tglapprove = res.data[0]["tglapprove"];
          this.rowizin.approvedby = res.data[0]["approvedby"];
        });
    },
    dataselected(item) {
      console.log(item);
      this.rowizin = item;
      this.modal = true;
    },
    refresh() {
      axios
        .get(
          this.$appUrl + "getizinadmin.php?jenis=" + this.opt + "&q=" + this.search +
          "&token=" + this.$globalData.Token + "&tg1=" + this.tg1 + "&tg2=" + this.tg2
        )
        .then((res) => {
          this.info = res.data;
        });
    },
  },
};
</script>

<style></style>
