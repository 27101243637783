<template>
  <div class="container">
    <!-- <div class="row justify-content-center"> -->
    <!-- <div class="col-md-auto"> -->
    <b-card class="col-md-4 px-0 mt-4 mb-4 text-center shadow" header="Login Page">
      <b-form>
        <b-input-group size="md">
          <b-form-input v-model="$globalData.UserId" placeholder="nama user" />
          <b-input-group-append is-text>
            <b-icon-person-check />
          </b-input-group-append>
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-form-input v-model="password" type="password" placeholder="password" />
          <b-input-group-append is-text>
            <b-icon-key />
          </b-input-group-append>
        </b-input-group>

        <!-- Password:
            <b-form-input v-model="password" type="password" id="pwd" /> -->
      </b-form>
      <b-button class="float-right mt-3 col-6" block @click="gologin"><b-icon-arrow-right />Login</b-button> </b-card>{{
        $globalData.LoginOK ? "Login Sukses ... " : "Login Gagal.." }}
    <i class="fa-solid fa-screwdriver-wrench"></i>
    <!-- </div> -->
    <!-- <div class="col">
        <img src="../assets/inward.png" width="60%" class="rounded" />
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  components: {
    //  Navbar,
  },
  data() {
    return {
      password: "",
      ketlogin: "",
    };
  },
  mounted() {
    this.$globalData.UserId = localStorage.getItem("userid") || "";
  },
  methods: {
    gettahun() {
      axios.get(this.$appUrl + "gettahun.php").then((res) => {
        this.$globalData.CurrentYear = res.data;
      });
    },
    gologin_() {
      this.$globalData.islogin = false;
      this.$router.push({ path: "/jurnal" });
    },
    gologin() {
      this.gettahun();

      axios
        .post(this.$appUrl + "login.php", {
          username: this.$globalData.UserId,
          password: this.password,
        })
        .then((res) => {
          console.log("reslogin", res.data);

          if (res.data.user.length != 0) {
            this.$globalData.LoginOK = true;
            localStorage.setItem("userid", this.$globalData.UserId);
            this.$globalData.TokenOK = 1;
            this.$globalData.LevelLogin = res.data.user[0]["level"];
            this.$globalData.pasfoto = res.data.user[0]["pasfoto"];
            this.$globalData.Token = res.data.token;
            // this.$globalData.Token = res.data[0]["token"];
            // localStorage.setItem("mytokenBonding", res.data[0]["token"]);

            this.ketlogin = "Login Sukses ......";
            this.$globalData.islogin = false;
            this.$globalData.hakuser = res.data.hak;
            this.$globalData.datauser = res.data.user[0];
            this.$globalData.isactive = "dashboard";
            localStorage.setItem("userid", this.$globalData.UserId);
            this.$router.push({ path: "/dashboard" });
          } else {
            this.$globalData.LoginOK = false;
            this.$globalData.TokenOK = 0;
            this.ketlogin = "Login gagal";
            this.$globalData.islogin = true;

            this.$globalData.Token = "";
            this.$bvModal.msgBoxOk("User & Password tidak sesuai ...", {
              centered: true,
            });
          }
        });
    },
  },
};
</script>

<style></style>
