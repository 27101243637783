<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 mb-2">
        <b-card>
          <template #header>
            <h4 class="mb-0">List Disiplin</h4>
          </template>
          <div class="row">
            <div class="col-md-6">
              <b-input-group size="sm" class="mb-2">
                <b-form-input v-model="search" placeholder="pencarian topik" @keyup.enter="getdata()" />
                <b-input-group-append is-text variant="primary">
                  <b-icon-search size="sm" style="cursor: pointer" @click="getdata()" />
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-md-6">
              <div class="row">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"
                  @input="getdata()" size="sm"></b-pagination>
                <span style="font-size: 12px" class="mt-2 ml-2">#{{ totalRows }}</span>
              </div>
            </div>
          </div>
          <!-- total {{ totalRows }} -->
          <!-- {{ datadisiplin }} -->
          <b-table hover :fields="['no', 'nodisiplin', 'tanggal', 'nama', 'jenis', 'status']" :items="datadisiplin"
            @row-clicked="dataselected" responsive style="font-size: 14px" :tbody-tr-class="rowClass">
            <template #cell(no)="row">
              {{ row.index + (currentPage - 1) * perPage + 1 }}
            </template>
          </b-table>
        </b-card>
      </div>
      <div class="col-md-6">
        <b-card>
          <template #header>
            <div>
              <h4 class="mb-0">
                Data Disiplin (
                {{ form.nodisiplin }})
              </h4>
            </div>
          </template>
          <b-form @submit="onSubmit" @reset="onReset">
            <div class="row mb-0" size="sm">
              <div class="col-md-8">
                Nama Siswa:
                <vue-bootstrap-typeahead class="mb-4" size="sm" ref="typeahead" v-model="query" :data="users"
                  :serializer="(item) => item.nama" @hit="
                    selectedUser = $event;
                  form.nis = selectedUser.nis;
                  form.noasrama = selectedUser.noasrama;
                  form.kelas = selectedUser.nokelas;
                  " placeholder="pencarian nama" />
              </div>

              <div class="col-md mt-1"><br />NIS:{{ selectedUser.nis }}</div>
              <!-- {{ form.nis }},{{ selectedUser }} -->
            </div>
            <div class="row">
              <div class="col-md-3">
                <b-img :src="$appUrl + selectedUser.pasfoto" class="lonjong-image" rounded />
              </div>
              <div class="col-md-auto">
                Nama Wali <b>{{ selectedUser.namawali }}</b> <br />
                Asrama
                <b>{{ selectedUser.namaasrama }} {{ selectedUser.noasrama }}</b><br />
                NoHP Ortu <b>{{ selectedUser.nohp }}</b> <br />
                Kelas
                <b>{{ selectedUser.nokelas }}, {{ selectedUser.namakelas }}</b>
              </div>
            </div>
            <div class="row mt-2 mb-2">
              <div class="col-md">
                Tanggal Kejadian:
                <b-form-input type="date" v-model="form.tanggal" required size="sm" />
              </div>
              <div class="col-md">
                Kategori:
                <b-form-select v-model="form.kategori" class="col" size="sm"
                  style="background-color: white; font-weight: bold" :options="[
                    { value: 'ringan', text: 'ringan' },
                    { value: 'sedang', text: 'Sedang' },
                    { value: 'berat', text: 'Berat' },
                  ]" />
                <!-- {{ form.waktu }} -->
              </div>
            </div>
            Jenis Pelanggaran: {{ form.jenispelanggaran }}
            <b-form-select class="mb-0 mb-2" v-model="form.jenispelanggaran" :options="listdisiplin" size="sm" required
              multiple :select-size="5" style="white-space: pre-wrap" />

            Deskripsi: <br />
            <!-- {{ getTextFromValue(form.jenispelanggaran) }} -->
            <li v-for="selectedOption in form.jenispelanggaran" :key="selectedOption">
              {{ selectedOption }}{{ getTextFromValue(selectedOption) }}
            </li>

            Kronologis:
            <b-form-textarea class="mb-2" v-model="form.kronologis" required size="sm" />

            <div class="row">
              <div class="col">
                Saksi 1:
                <b-form-input class="mb-2" v-model="form.saksi1" required size="sm" />
              </div>
              <div class="col">
                Saksi 2:
                <b-form-input class="mb-2" v-model="form.saksi2" size="sm" />
              </div>
            </div>
            Sangsi:
            <b-form-textarea class="mb-2" v-model="form.sangsi" required size="sm" />
            Evaluasi:
            <b-form-textarea class="mb-2" v-model="form.evaluasi" required size="sm" />
            <div class="row justify-content-between ml-2">
              <div>
                <b-button type="submit" variant="primary" size="sm" class="mr-2 mb-2">{{ isnew ? "Submit" : "Update"
                  }}</b-button>
                <b-button type="reset" variant="danger" size="sm" class="mr-2 mb-2">Reset</b-button>
                Mode: {{ isnew ? "New" : "Edit" }}
              </div>
              <b-input-group prepend="Status" class="mb-2 col-md-4 float-right" size="sm">
                <b-form-select v-model="form.status" size="sm" style="background-color: white; font-weight: bold"
                  :options="[
                    { value: '0', text: 'Open' },
                    { value: '1', text: 'Closed' },
                  ]" /></b-input-group>
            </div>
            <!-- {{ form }} -->
            <hr />
            <Upload :nomorid="form.nodisiplin" />
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Upload from "@/components/upload.vue";
import _ from "lodash";

export default {
  name: "Disiplin",
  components: { Upload },
  data() {
    return {
      options: {},
      selectedCountry: null,
      datadisiplin: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 15,
      search: "",
      isnew: true,
      deskripsi: "",
      dok: null,
      listdisiplin: [],
      form: {
        identify: "",
        status: "0",
        nis: "",
        unik: "",
        tanggal: "",
        jenispelanggaran: [],
        kronologis: "",
        tahun: "",
        kategori: "ringan",
        userid: "",
        saksi1: "",
        saksi2: "",
        sangsi: "",
        evaluasi: "",
        noasrama: "",
        nodisiplin: "",
        kelas: "",
      },
      selectedItem: "",
      suggestionsList: [],
      query: "",
      users: [],
      selectedUser: {},
      selectedrow: "",
    };
  },
  mounted() {
    this.form.tanggal = this.$func.tglhariini();
    this.form.waktu = "10:00";
    this.getdata();
    axios
      .get(
        this.$appUrl + `listdisiplin.php` + "?token=" + this.$globalData.Token
      )
      .then((res) => {
        this.listdisiplin = res.data;
      });
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    querys(newQuery) {
      axios
        .get(
          this.$appUrl +
          `searchdisiplin.php?q=${newQuery}` +
          "&token=" +
          this.$globalData.Token & + "&level=" + this.$globalData.LevelLogin + "&user=" + this.$globalData.UserId
        )
        .then((res) => {
          this.users = res.data;
        });
    },
    query: _.debounce(function (newQuery) {
      this.searchUsers(newQuery);
    }, 500),
  },

  methods: {
    rowClass(item, type) {
      console.log("item", item.nodisiplin, this.selectedrow);
      if (!item || type !== "row") return;
      if (item.nodisiplin === this.selectedrow) return "table-success";
    },
    searchUsers(newQuery) {
      axios
        .get(
          this.$appUrl +
          `searchdisiplin.php?q=${newQuery}` +
          "&token=" +
          this.$globalData.Token + "&level=" + this.$globalData.LevelLogin + "&user=" + this.$globalData.UserId
        )
        .then((res) => {
          this.users = res.data;
        });
    },
    onReset(event) {
      event.preventDefault();
      // this.form = {};
      // this.form.nodisiplin = "";
      this.isnew = true;
      this.form = {
        identify: 0,
        nis: "",
        unik: "",
        tanggal: "",
        jenispelanggaran: [],
        kronologis: "",
        tahun: "",
        kategori: "",
        userid: "",
        saksi1: "",
        saksi2: "",
        sangsi: "",
        evaluasi: "",
        noasrama: "",
        nodisiplin: "",
        status: "0",
      };
      this.$refs.typeahead.inputValue = "";
      this.selectedUser = {};
    },

    dataselected(item) {
      console.log("item selected ", item);
      this.isnew = 0;
      this.selectedrow = item.nodisiplin;
      this.form = {
        identify: item.identify,
        nis: item.nis,
        unik: item.unik,
        tanggal: item.tanggal,
        jenispelanggaran: JSON.parse(item.jenispelanggaran),
        kronologis: item.kronologis,
        tahun: item.tahun,
        kategori: item.kategori,
        userid: item.userid,
        saksi1: item.saksi1,
        saksi2: item.saksi2,
        sangsi: item.sangsi,
        kelas: item.kelas,
        evaluasi: item.evaluasi,
        noasrama: item.noasrama,
        nodisiplin: item.nodisiplin,
        nama: item.nama,
        status: item.status,
      };
      this.$refs.typeahead.inputValue = item.nama;
      this.selectedUser.nis = item.nis;
      this.selectedUser.namaasrama = item.namaasrama;
      this.selectedUser.noasrama = item.noasrama;
      this.selectedUser.nohp = item.nohp;
      this.selectedUser.nokelas = item.kelas;
      this.selectedUser.namawali = item.namawali;
      this.selectedUser.pasfoto = item.pasfoto;
      this.selectedUser.namakelas = item.namakelas;
      // alert("masuk doubleklik");
    },
    getdata() {
      axios
        .get(
          this.$appUrl +
          "getdisiplin.php?token=" +
          this.$globalData.Token +
          "&page=" +
          this.currentPage +
          "&perpage=" +
          this.perPage +
          "&jmlrow=" +
          this.totalRows +
          "&q=" +
          this.search
        )
        .then((res) => {
          // if (res.data.length != 0) {
          //   this.details = res.data;
          // }
          console.log("Data ", res.data);
          this.datadisiplin = res.data.records;
          if (this.currentPage == 1) {
            this.totalRows = res.data.jumlahrecord;
          }
          console.log(
            "rows",

            this.datadisiplin
          );
        });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Yakin untuk di Simpan ?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post(
                this.$appUrl +
                "savedisiplin2.php?token=" +
                this.$globalData.Token +
                "&isnew=" +
                this.isnew +
                "&username=" +
                this.$globalData.UserId,
                { data: this.form },
                {
                  crossDomain: true,
                }
              )
              .then((res) => {
                console.log("data", res.data);
                if (this.isnew == 1) {
                  this.form.nodisiplin = res.data[0].nodisiplin;
                }
                this.$toast.success(
                  this.isnew == 1 ? "Sukses Save" : "Sukses Update",
                  {
                    type: "success",
                    position: "top-right",
                    duration: 3000,
                    dismissible: true,
                  }
                ); //   output.className = 'container';
                this.isnew = 0;
                // this.refresh("2");
              });
          }
        });
    },
    searchItems() {
      console.log("search item");
    },
    getTextFromValue(value) {
      // console.log("value option ", value);
      const selectedOption = this.listdisiplin.find(
        (option) => option.value === value
      );
      return selectedOption ? selectedOption.text : "";
    },
  },
};
</script>

<style>
.gradient {
  background: linear-gradient(to bottom, #134a6e 33%, #66ff99 100%);

  .custom-dropdown-item {
    max-height: 20px;
    /* Ganti dengan tinggi yang Anda inginkan */
    overflow: hidden;
  }

  .typeahead-item .list-group-item {
    max-height: 10px;
    /* Ganti dengan tinggi yang Anda inginkan */
    overflow: hidden;
  }
}

.lonjong-image {
  width: 120px;
  /* Ganti dengan lebar yang Anda inginkan */
  height: 150px;
  /* Ganti dengan tinggi yang Anda inginkan */
  object-fit: cover;
  /* Menjaga proporsi gambar tetap proporsional */
}
</style>
