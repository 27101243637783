<template>
  <div class="container-fluid p-2">
    <b-card header="Check IT">
      <div class="row pr-2 pl-2">
        <div class="col-md-4">
          <Showdata namatable="cekasrama" judul="Santri > 1 Asrama" />
          <Showdata namatable="cekkelas" judul="Santri > 1 Kelas" />
        </div>
        <div class="col-md-4">
          <Showdata namatable="cekpesertakelas" judul="Santri no Kelas" />
        </div>

        <div class="col-md-4">
          <Showdata namatable="cekpesertaasrama" judul="Santri no Asrama" />
        </div>
      </div>
    </b-card>
    <div class="row mt-3">
      <div class="col-md-6">
        <Showdata namatable="userid" judul="Data Userid" />
      </div>
      <div class="col-md-6">
        <Showdata namatable="datapmb" judul="Data PMB" />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-6">
        <Showdata namatable="kelas" judul="Data Kelas" />
      </div>
      <div class="col-md-6">
        <Showdata namatable="waliasrama" judul="Wali Asrama" />
      </div>
    </div>
    <div class="row mt-3">
      <Showdata namatable="siswa" judul="Data Siswa" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Showdata from "@/aplikasi/showdata.vue";
// import Showprofile from "@/aplikasi/showprofile.vue";

export default {
  name: "Setting",
  components: { Showdata },
  data() {
    return {
      datapmb: [],
    };
  },
  mounted() {
    this.refresh("datapmb");
  },
  methods: {
    refresh(para) {
      axios
        .get(
          this.$appUrl +
            "showdata.php?token=" +
            this.$globalData.Token +
            "&para=" +
            para
        )
        .then((res) => {
          this.datapmb = res.data;
        });
    },
  },
};
</script>

<style>
.gradient {
  background: linear-gradient(to bottom, #134a6e 33%, #66ff99 100%);
}
</style>
