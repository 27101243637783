<template>
  <div class="fluid">
    <div class="text-white bg-dark text-center" @click="showdownload()">
      Data Uploads: {{ nomorid }}
    </div>
    <div class="row">
      <div class="col-md-6">
        Deskripsi Data:
        <b-form-input size="sm" v-model="deskripsi" rows="4" />
        <div class="row mt-3 mb-2">
          <b-form-file v-model="dok" class="mt-0 col-md ml-3 col-9" size="sm">
          </b-form-file>
          <b-button
            size="sm"
            :disabled="nomorid == '' || dok == null"
            @click="uploadfile(dok)"
            class="ml-2 mr-2"
            ><b-icon-upload
          /></b-button>
        </div>
        <!-- {{ dok }} - {{ nomorid }} -->
        <b-progress
          :value="valuedownload"
          max="100"
          show-progress
          animated
        ></b-progress>
      </div>
      <div class="col-md-6">
        <!-- {{ listupload.length }} -->
        <div v-show="listupload.length == 0" class="mt-2 small">
          No Data Upload
        </div>
        <b-list-group class="mt-2">
          <b-list-group-item
            v-for="(detail, k) in listupload"
            :key="k"
            class="pb-1 pt-1"
          >
            <b-icon-trash
              size="sm"
              style="cursor: pointer !important"
              @click="deleteupload(detail.identify, detail.namafile)"
            />
            <a href="#" @click="showupload(detail.url)">
              <span style="font-size: 13px">{{
                detail.deskripsi == "" ? detail.namafile : detail.deskripsi
              }}</span>
            </a>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Upload",
  props: ["nomorid"],
  data() {
    return {
      listupload: [],
      deskripsi: "",
      dok: null,
      valuedownload: 0,
      maxdownload: 100,
    };
  },
  watch: {
    nomorid: function (val) {
      this.nomorid = val;
      this.showdownload();
    },
    // status: function (val) {
    //    this.schedule = val == 5 ? "d-none" : "";
    // },
    // tab: function (val) {
    //    console.log("tab", val);
    // },
  },
  methods: {
    randomValue() {
      this.valuedownload = Math.random() * this.maxdownload;
    },
    showupload(url) {
      window.open(this.$appUrl + url);
    },
    deleteupload(index, nf) {
      this.$bvModal
        .msgBoxConfirm("Apakah yakin Untuk diHapus ? " + nf, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okTitle: "YES",
          cancelTitle: "NO",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          if (value) {
            axios
              .get(
                this.$appUrl +
                  "removedoc.php?token=" +
                  this.$globalData.Token +
                  "&iden=" +
                  index
              )
              .then((res) => {
                this.showdownload();
                console.log(res);
              });
          }
        });
    },
    showdownload() {
      axios
        .get(
          this.$appUrl +
            "getdoc.php?token=" +
            this.$globalData.Token +
            "&para=" +
            this.nomorid
        )
        .then((res) => {
          this.listupload = res.data;
          this.$emit("dataupload", res.data);
        });
    },
    uploadfile(nf) {
      // console.log("uploadfile", nf, th, index);
      //   this.valuedownload = 0;
      var config = {
        onUploadProgress: (progressEvent) => {
          // ini harus menggunakan arrow function
          console.log("progress event", progressEvent);
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.valuedownload = percentCompleted;
          console.log("progress", percentCompleted, this.valuedownload);
        },
        headers: {
          "Content-Type": "multipart/header-data",
        },
      };
      var data = new FormData();
      data.append("deskripsi", this.deskripsi);
      data.append("noid", this.nomorid);
      data.append("file", nf);
      axios
        .post(
          this.$appUrl + "upload.php?token=" + this.$globalData.Token,
          data,
          config
        )
        .then((res) => {
          //  th.target.innerText = res.data.status;
          this.dok = null;
          this.showdownload();
          this.$toast.success(res.data.status, {
            type: "success",
            position: "top-right",
            duration: 3000,
            dismissible: true,
          }); //   output.className = 'container';
          //   output.innerHTML = res.data;
        })
        .catch(function (err) {
          console.log("error", err);
          //   output.className = 'container text-danger';
          //   output.innerHTML = err.message;
        });
    },
  },
};
</script>

<style>
.gradient {
  background: linear-gradient(to bottom, #134a6e 33%, #66ff99 100%);
}
.jaraklist {
  margin-bottom: 1px; /* Atur margin bawah sesuai kebutuhan Anda */
}
</style>
